import { createSlice } from '@reduxjs/toolkit' //next js redux toolkit  

export const uploadSlice = createSlice({
    name: 'upload',
    initialState: {
        progress:0,
        show:false,
        reverse: false
    },
    reducers: {
        updateProgress: (state, action) => {
            state.progress = action.payload.progress
        },
        showProgress: (state, action) => {
            state.show = action.payload.show
        },
        reverseProgress: (state, action) => {
            state.reverse = action.payload.reverse
        }
    }
})
// case under reducers becomes an action 
export const {  updateProgress, showProgress, reverseProgress } = uploadSlice.actions
export default uploadSlice.reducer 