import { Button, Flex, Icon, Menu, MenuButton, MenuItem, MenuList, Radio, RadioGroup, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { votingArrowLeft } from "../icons/icons";
import { FaArrowRight } from "react-icons/fa";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { ChevronDownIcon } from "@chakra-ui/icons";

function Header({setModal,setEditEventData}) {
  const [selectedFilter, setSelectedFilter] = useState('All');
  const [filteredData, setFilteredData] = useState();

  const handleFilterClick = (value) => {
    setSelectedFilter(value);

    switch (value) {
      case 'All':
        // setFilteredData(data);
        break;
      case 'ToDo':
        // setFilteredData(data.filter((item) => item.markAs === 'todo'));
        break;
      case 'In-Progress':
        // setFilteredData(data.filter((item) => item.markAs === 'in-progress'));
        break;
      case 'Completed':
        // setFilteredData(data.filter((item) => item.markAs === 'completed'));
        break;
      case 'Backlogs':
        // setFilteredData(data.filter((item) => item.markAs === 'backlogs'));
        break;
      default:
        // setFilteredData(data);
    }
  };
  const location = useLocation();
  const isDirectorPage = location.pathname.includes("/taskflow") || location.pathname.includes("/managetask") || location.pathname.includes("/singletask");
  const isInvestorPage = location.pathname.includes("/track");
  const isManagePage=location.pathname.includes("/managetask");
 
  let displayText = "";

  if (isDirectorPage) {
    displayText = "Schedule Task";
  } else if (isInvestorPage) {
    displayText = "Create an Event";
  }
  const handleClick = () => {
    if (isInvestorPage) {
      // Set the modal to be open
      setModal(true);
      setEditEventData(null);
 
    } 
  };
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Go back one step in the history
  };

  return (
    <Flex
      mb="0.5rem"
      p="0.5rem"
      alignItems={"center"}
      w="100%"
      justifyContent={"space-between"}
      bg="rgba(227, 244, 251, 0.75)"
      borderRadius={"10px"}
    >
      <Flex gap="0.5rem" alignItems={"center"} onClick={handleGoBack} cursor="pointer">
        <Icon as={votingArrowLeft}/>
        <Text
          fontFamily={"Poppins"}
          fontSize={"0.8rem"}
          fontWeight={600}
          color="rgba(0, 0, 0, 1)"
        >
          Task Flow
        </Text>
      </Flex>
     {displayText!=="" &&
      <Link
      display={isManagePage ? 'none':'block'}
      to={isInvestorPage ? "#" : "/addtask"}>
        <Button
              display={isManagePage ? 'none':'flex'}

          h="1rem"
          p="1rem"
          bg="rgba(255, 255, 255, 1)"
          border={"1px solid rgba(15, 66, 114, 1)"}
          borderRadius={"60px"}
          onClick={handleClick}
        >
          <Flex w="100%" gap="1rem" justifyContent={"space-between"}>
            <Text
              fontFamily={"Poppins"}
              fontSize={"0.7rem"}
              fontWeight={500}
              color="rgba(15, 66, 114, 1)"
            >
              {displayText}
            </Text>
            <HiOutlineArrowNarrowRight color="rgba(15, 66, 114, 1)" />
          </Flex>
        </Button>
      </Link>}

      {isManagePage &&
      <Menu>

        
       <MenuButton 
       _hover={{bg:'rgba(255, 255, 255, 1)'}}
      bg="rgba(255, 255, 255, 1)"
      as={Button} rightIcon={<ChevronDownIcon />}>
              <Text>{selectedFilter}</Text>
      </MenuButton>

      <MenuList
                  borderRadius="0px 0px 10px 10px"

                fontFamily={"Poppins"} fontWeight={400} fontSize={"0.7rem"} color="rgba(0, 0, 0, 1)"

      >
        <RadioGroup defaultValue="All"
        //  value={selectedFilter}
        //  onChange={(value) => handleFilterClick(value)}
         >

             {/*  disabled  all list  */}
          <MenuItem
          w="100%"
          pb="0.6rem"
          // borderBottom={"1px solid black"}
          
          >
            <Flex justifyContent={"space-between"} w="100%">
              <Text>All</Text>
              <Radio value="All" defaultChecked/>

            </Flex>
          </MenuItem>

             
          <MenuItem
          w="100%"
          pb="0.6rem"
          borderBottom={"1px solid black"}
          display={"none"}
          
          >
            <Flex justifyContent={"space-between"} w="100%">
              <Text>Sales</Text>
              <Radio value="ToDo"/>

            </Flex>
            </MenuItem>
        

            <MenuItem
          w="100%"
          pb="0.6rem"
          borderBottom={"1px solid black"}
          display={"none"}
          
          >
            <Flex justifyContent={"space-between"} w="100%">
              <Text>Marketing</Text>
              <Radio value="marketing"/>

            </Flex>
            </MenuItem>
        

            <MenuItem
          w="100%"
          pb="0.6rem"
          borderBottom={"1px solid black"}
          display={"none"}
          
          >
            <Flex justifyContent={"space-between"} w="100%">
              <Text>Development</Text>
              <Radio value="development"/>
            </Flex>
            </MenuItem>
            
       
            <MenuItem
          w="100%"
          pb="0.6rem"
          display={"none"}
          
          >
            <Flex justifyContent={"space-between"} w="100%">
              <Text>Testing</Text>
              <Radio value="Backlogs"/>
            </Flex>
            </MenuItem>
        </RadioGroup>
      </MenuList>
    </Menu>
      }
    </Flex>
  );
}

export default Header;
