import React, { useState } from "react";
import { Box, Text, Input, Button, Flex } from "@chakra-ui/react";

export default function ImageUpload() {
  const [isDragActive, setIsDragActive] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleDragEnter = () => {
    setIsDragActive(true);
  };

  const handleDragLeave = () => {
    setIsDragActive(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragActive(false);

    const file = e.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const handleInputChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleClearFile = () => {
    setSelectedFile(null);
  };

  return (
    <Box
      border="2px dashed"
      borderColor={isDragActive ? "teal.500" : "gray.300"}
      borderRadius="md"
      p={2}
      textAlign="center"
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={(e) => e.preventDefault()}
      onDrop={handleDrop}
      mt={-9}
    >
      {selectedFile ? (
        <Box>
          <Text className="truncate text-xs mt-3">{selectedFile.name}</Text>
          <Text
            as="button"
            color="red.500"
            fontWeight="bold"
            mt={6}
            onClick={handleClearFile}
          >
            Clear File
          </Text>
        </Box>
      ) : (
        <Box>
          <Text fontSize="0.7rem" color="gray.400" my={2}>
            {isDragActive
              ? "Drop the files here!"
              : "Drag and drop files here or click to select"}
          </Text>
          <Flex w="100%" justifyContent={"flex-end"}>
            <Button
              bg="rgba(99, 59, 188, 0.7)"
              borderRadius="2px"
              _hover={{ transform: "scale(0.9)", bg: "rgba(99, 59, 188, 1)" }}
              h="2rem"
            >
              <label htmlFor="file-input">
                <Text
                  as="span"
                  bg="zinc.200"
                  color="rgba(255, 255, 255, 1)"
                  fontSize="0.7rem"
                  fontWeight="semibold"
                  rounded="md"
                >
                  Upload
                </Text>
              </label>
              <Input
                id="file-input"
                type="file"
                display="none"
                onChange={handleInputChange}
                accept=".jpg, .jpeg, .png"
              />
            </Button>
          </Flex>
        </Box>
      )}
    </Box>
  );
}
