import React, { useState } from "react";
import { Flex, Text, IconButton, Box } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { FaCalendarAlt, FaCircle } from "react-icons/fa";
import { useSelector } from "react-redux";

const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

const generateCalendar = (year, month) => {
  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);
  const daysInMonth = lastDay.getDate();
  const startDay = firstDay.getDay();

  const calendar = [];
  let dayCounter = 1;

  for (let i = 0; i < 6; i++) {
    const week = [];
    for (let j = 0; j < 7; j++) {
      if ((i === 0 && j < startDay) || dayCounter > daysInMonth) {
        week.push(null);
      } else {
        week.push(dayCounter);
        dayCounter++;
      }
    }
    calendar.push(week);
    if (dayCounter > daysInMonth) {
      break;
    }
  }

  return calendar;
};

const BigCalendar2 = ({ eventData,year,month,setYear,setMonth }) => {
  console.log(eventData,'event data');
  const currentDate = new Date();
  const calendar = generateCalendar(year, month);

  const handlePrevMonth = () => {
    setMonth((prevMonth) => (prevMonth === 0 ? 11 : prevMonth - 1));
    setYear((prevYear) => (month === 0 ? prevYear - 1 : prevYear));
  };

  const handleNextMonth = () => {
    setMonth((prevMonth) => (prevMonth === 11 ? 0 : prevMonth + 1));
    setYear((prevYear) => (month === 11 ? prevYear + 1 : prevYear));
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", {
    month: "long",
    year: "numeric",
  }).format(new Date(year, month));
const leaveType=useSelector((state)=>state.org.organizationData?.leavTypes)

const stringToColor = (str, opacity = 1) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const c = (hash & 0x00FFFFFF).toString(16).toUpperCase();
  return `rgba(${parseInt(c.slice(0, 2), 16)}, ${parseInt(c.slice(2, 4), 16)}, ${parseInt(c.slice(4, 6), 16)}, ${opacity})`;
};

const groupEventsByDateRange = (events) => {
  const groupedEvents = {};
  events.forEach((event) => {
    const eventStartDate = new Date(event.from);
    const eventEndDate = new Date(event.to);
    let currentDate = new Date(eventStartDate);

    while (currentDate <= eventEndDate) {
      const date = currentDate.toISOString().split('T')[0];
      if (!groupedEvents[date]) {
        groupedEvents[date] = [];
      }
      groupedEvents[date].push(event);
      currentDate.setDate(currentDate.getDate() + 1);
    }
  });

  return groupedEvents;
};

const groupedEvents = groupEventsByDateRange(eventData);



  return (
    <Flex direction="column" align="center" w="full">
      <Flex align="center" mb={4} w="full" justify="center">
        <Box display="flex" alignItems="center" justifyContent="center">
          <IconButton
            icon={<ChevronLeftIcon />}
            onClick={handlePrevMonth}
            aria-label="Previous Month"
            variant="outline"
          />
          <Text fontWeight={500} fontFamily="poppins" fontSize="1.1rem" mx={4}>
            {formattedDate}
          </Text>
          <IconButton
            icon={<ChevronRightIcon />}
            onClick={handleNextMonth}
            aria-label="Next Month"
            variant="outline"
          />
        </Box>
      </Flex>
      <Flex direction="column" align="center" w="full">
        <Flex borderColor="#969696" w="full">
          {daysOfWeek.map((day) => (
            <Text
              key={day}
              //   mx={2}
              p={2}
              fontWeight="bold"
              fontFamily="poppins"
              border="0.5px solid"
              borderColor="#969696"
              w={`${100 / 7}%`}
              textAlign="center"
              bgColor={day === "SUN" ? "#FFEBEB" : "transparent"}
              color={day === "SUN" ? "#E92C2C" : "inherit"}
            >
              {day}
            </Text>
          ))}
        </Flex>
        {calendar.map((week, index) => (
  <Flex key={index} justify="space-around" w="full">
    {week.map((day, dayIndex) => {
      const currentDate = new Date(Date.UTC(year, month, day));
      const formattedDate = currentDate.toISOString().split('T')[0];
      const eventsForDay = groupedEvents[formattedDate] || [];

      return (
        <Box
          key={dayIndex}
          position="relative"
          p={8}
          textAlign="center"
          border="0.2px solid"
          borderColor="#969696"
          w={`${100 / 7}%`}
          bg={
            day !== null &&
            day === new Date().getDate() &&
            month === new Date().getMonth()
              ? 'gray.100'
              : 'transparent'
          }
          
        >
          {day !== null && (
            <Text position="absolute" top="0" right="0" fontSize="1.2rem">
              {day}
            </Text>
          )}

          {eventsForDay.map((event, eventIndex) => (
            <Box
              key={eventIndex}
              position="absolute"
              top={`${eventIndex * 2}rem`} // Adjust the top position based on the number of events
              left="0rem" // Adjust the left position based on the day
              pl="0.1rem"
            >
              {event.type === 'event' ? (
                <>
                  
                   
                    <Text
                      pl="0.5rem"
                      fontFamily="poppins"
                      textAlign="left"
                      color="#E92C2C"
                      bg="#FFEBEB"
                      fontSize="0.7rem"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      
                    >
                   <Flex alignItems="center"> <FaCalendarAlt />   {event.name.length > 10
                        ? `${event.name.slice(0, 10)}...`
                        : event.name}
                        </Flex>
                    </Text>
                  
                </>
              ) : (
                <Text
                  color={stringToColor(event.leaveType)}
                  pl="0.5rem"
                  fontFamily="poppins"
                  textAlign="left"
                  bg={stringToColor(event.leaveType, 0.2)}
                  fontSize="0.7rem"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                >
                  {event.name.length > 10
                    ? `${event.name.slice(0, 10)}...`
                    : event.name}
                </Text>
              )}
            </Box>
          ))}
        </Box>
      );
    })}
  </Flex>
))}

      </Flex>
      <Flex justifyContent="space-around">
      {leaveType?.map((leave, index) => (
  <Flex ml="0.5rem" fontFamily="poppins" key={index} alignItems="center">
    <FaCircle color={stringToColor(leave.leaveType)} />
    {leave.leaveType}
  </Flex>
))}
</Flex>
    </Flex>
  );
};

export default BigCalendar2;
