import React, { useState } from 'react'
import Header from './Header'
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Checkbox, Flex, FormControl, FormLabel, Input, Radio, RadioGroup, Select, Stack, Text } from '@chakra-ui/react'
import { IoIosArrowForward } from 'react-icons/io'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { HiOutlinePlusSmall } from 'react-icons/hi2'
import { RxCross1 } from 'react-icons/rx'
import Multiselect from 'multiselect-react-dropdown'
import { useSelector } from 'react-redux'
import ImageUpload from './ImageUpload'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import axios from 'axios'
import { toast } from 'react-toastify'

function ScheduleTask() {
    const baseURL = process.env.REACT_APP_URL;
    const navigate = useNavigate();
    const [errors,setErrors] = useState([])
    const {id}=useParams()

  const [subTasks, setSubTasks] = useState([]);
  const [description, setdescription] = useState("");
  const [subOpen, isSubOpen] = useState(false);
  const [users, setUsers] = useState(null);
  const [assignToMe, setAssignToMe] = useState(false);
  const [files, setFiles] = useState(null);

  //multiselect state
  const [selectedDepartment, setSelectedDepartment] = useState()
  console.log(selectedDepartment,'selecteddd departmenttt')
   
  
   // const softwareList = [organizationData];
   const softwareList = useSelector(state => state.org.organizationData.departments);
   console.log(softwareList,'softwarelisttttt')
   
 const handleDepartmentselect = (selected, selectedItem) => {
    setSelectedDepartment(selected);
  };

  const handleMultiselectChange = (selectedList, selectedItem) => {
    setSelectedOptions(selectedList);
  };

  //options
  const options = users?.map(item => ({
    name: `${item.firstname} ${item.lastname}`,
    _id: item._id
  }));

  const departments=softwareList?.map((software, index) => ({
    name: software,
    id: index + 1
  }));


  
  const [selectedOptions, setSelectedOptions] = useState([]);
console.log(selectedOptions,'selecteedddddddddddddddddddd')
  



  const handleInputChange = (e) => {
    setdescription(e.target.value);
  };

  const handleSaveTask = () => {
    if (description.trim() !== "") {
      // Create a new task object with checkbox and input values
      const newTask = {
        id: subTasks.length + 1,
        description,
      };

      // Update the subTasks array with the new task
      setSubTasks([...subTasks, newTask]);
      setdescription("");
    }
    isSubOpen(false);
  };
  const handleRemoveItem = (index) => {
    // Create a new array excluding the item at the specified index
    const updatedItems = [
      ...subTasks.slice(0, index),
      ...subTasks.slice(index + 1),
    ];
    setSubTasks(updatedItems);
  };

  const onSubmit = (values) => {
    // Handle form submission logic here
   
    if(selectedDepartment.length===0){
      setErrors([{departmentErr:true,message:"Select atleast one department"}])
      return
    }

    let startDate = new Date(values.startDate);

    const [hour, minute] = values.startTime.split(":");

    startDate.setHours(parseInt(hour, 10));
    startDate.setMinutes(parseInt(minute, 10));
    let formData = new FormData();

    let endDate = new Date(values.endDate);
    const [endhour, endminute] = values.endTime.split(":");

    endDate.setHours(parseInt(endhour, 10));
    endDate.setMinutes(parseInt(endminute, 10));
    let array = [];
    selectedOptions?.map((m) => array.push(m._id));
    if (assignToMe) {
      array.push(JSON.parse(localStorage.getItem("user")).registerid);
    }
    if(array.length===0){
      setErrors([{assignErr:true,message:"Select atleast one person as assignee"}])
      return
  }
    startDate = moment(startDate).format("YYYY-MM-DD HH:mm:ss");

    endDate = moment(endDate).format("YYYY-MM-DD HH:mm:ss");

    formData.append("startDate", startDate);
    formData.append("endDate", endDate);
    formData.append("title", values.taskTitle);
    formData.append("description", values.taskDescription);
    formData.append("priority", values.priority);
    formData.append("department", selectedDepartment);
   
    if(array.length == 0){
      formData.append("assignTo", array);
    }else{
      array.forEach((value, index) => {
        console.log(value,'vallllllllllllll.....')
        formData.append(`assignTo[${index}]`, value);
      });
    }
    
    subTasks.forEach((value, index) => {
      delete value.id;
    });
    subTasks.forEach((obj, index) => {
      Object.entries(obj).forEach(([key, value]) => {
        formData.append(`subtasks[${index}][${key}]`, value);
      });
    });
    formData.append("attachments", files);
if(id){
  axios
  .put(`${baseURL}/todo/tasks/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    },
  })
  .then((res) => {
    console.log(res.data);
    if (res.data.data) {
      navigate("/managetask");
    }
  })
  .catch((err) => {
    if (err.response.data.message) {
       toast.error(err.response.data.message, {
            position: "bottom-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });;
    } else if (err.response.data.payment === false) {
      navigate("/payment");
    }
  });
}else{
  axios
  .post(`${baseURL}/todo/tasks`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization:
        "Bearer " + JSON.parse(localStorage.getItem("user")).token,
    },
  })
  .then((res) => {
    console.log(res.data);
    if (res.data.data) {
      navigate("/managetask");
    }
  })
  .catch((err) => {
    if (err.response.data.message) {
       toast.error(err.response.data.message, {
            position: "bottom-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });;
    } else if (err.response.data.payment === false) {
      navigate("/payment");
    }
  });
}
   
  };

  return (
    <>
      <Header/>
      <Breadcrumb
          pl={0}
          color="rgba(51, 51, 51, 1)"
          fontFamily={"Poppins"}
          fontWeight={500}
          fontSize={"0.8rem"}
          spacing="8px"
          separator={<IoIosArrowForward color="gray.500" />}
        >
          <BreadcrumbItem pl="0rem">
            <BreadcrumbLink pl="0rem" href="/taskflow">TaskFlow</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href="#">Schedule task</BreadcrumbLink>
          </BreadcrumbItem>

        
        </Breadcrumb>
        <Formik
    initialValues={{
        startdate: '',
        starttime: '',
        enddate: '',
        endtime: '',
        title: '',
        description: '',
        department:'',
        participants:[],
        priority:'',


      }}
    //   validationSchema={validationSchema}
       onSubmit={onSubmit}
    >
      <Form>
        <Flex
        w="100%"
        >
          {/* Box 1 --------------------------- */}
          <Box
          p="2rem"
          w="50%"
          boxShadow='0px 3px 10px 0px rgba(0, 0, 0, 0.12)'
          bg="rgba(255, 255, 255, 1)"

          >
            <Flex mt="0.5rem">
              <FormControl>
                <FormLabel
                fontFamily={"Poppins"}
                fontWeight={500}
                color="rgba(17, 27, 43, 1)"
                fontSize={"0.7rem"}
                >Start Date</FormLabel>
                <Input
               
                  type="date"
                  name="startdate"
                  borderRadius="2px"
                  fontFamily={"Poppins"}
                  fontWeight={400}
                  color="rgba(65, 77, 99, 1)"
                  fontSize={"0.7rem"}
                  border="1px solid rgba(207, 217, 224, 1)"
                  boxShadow={"0px 2px 0px 0px rgba(231, 235, 238, 0.2) inset"}
                />
                <ErrorMessage name="date" component="div" className="error-message" />
              </FormControl>

              <FormControl ml={4}>
                <FormLabel
                 fontFamily={"Poppins"}
                 fontWeight={500}
                 color="rgba(0, 0, 0, 1)"
                 fontSize={"0.7rem"}
                >Start Time</FormLabel>
                <Input
                  type="time"
                  name="starttime"
                  borderRadius="2px"
                  fontFamily={"Poppins"}
                  fontWeight={400}
                  color="rgba(65, 77, 99, 1)"
                  fontSize={"0.7rem"}
                  border="1px solid rgba(207, 217, 224, 1)"
                  boxShadow={"0px 2px 0px 0px rgba(231, 235, 238, 0.2) inset"}
                
                />
                <ErrorMessage name="starttime" component="div" className="error-message" />
              </FormControl>
            </Flex>

            <Flex  mt="0.5rem">
              <FormControl>
                <FormLabel
                  fontFamily={"Poppins"}
                  fontWeight={500}
                  color="rgba(0, 0, 0, 1)"
                  fontSize={"0.7rem"}
                >End Date</FormLabel>
                <Input
                  type="enddate"
                  name="startdate"
                  borderRadius="2px"
                  fontFamily={"Poppins"}
                  fontWeight={400}
                  color="rgba(65, 77, 99, 1)"
                  fontSize={"0.7rem"}
                  border="1px solid rgba(207, 217, 224, 1)"
                  boxShadow={"0px 2px 0px 0px rgba(231, 235, 238, 0.2) inset"}
                
                />
                <ErrorMessage name="enddate" component="div" className="error-message" />
              </FormControl>

              <FormControl ml={4}>
                <FormLabel
                  fontFamily={"Poppins"}
                  fontWeight={500}
                  color="rgba(0, 0, 0, 1)"
                  fontSize={"0.7rem"}
                >Start Time</FormLabel>
                <Input
                  type="time"
                  name="endtime"
                  borderRadius="2px"
                  fontFamily={"Poppins"}
                  fontWeight={400}
                  color="rgba(65, 77, 99, 1)"
                  fontSize={"0.7rem"}
                  border="1px solid rgba(207, 217, 224, 1)"
                  boxShadow={"0px 2px 0px 0px rgba(231, 235, 238, 0.2) inset"}
               
                />
                <ErrorMessage name="endtime" component="div" className="error-message" />
              </FormControl>
            </Flex>

            <FormControl  mt="1.7rem">
                <FormLabel
                 fontFamily={"Poppins"}
                 fontWeight={500}
                 color="rgba(0, 0, 0, 1)"
                 fontSize={"0.7rem"}
                >Task Title</FormLabel>
                <Input
                  type="text"
                  name="title"
                  borderRadius="2px"
                  fontFamily={"Poppins"}
                  fontWeight={400}
                  color="rgba(65, 77, 99, 1)"
                  fontSize={"0.7rem"}
                  border="1px solid rgba(207, 217, 224, 1)"
                  boxShadow={"0px 2px 0px 0px rgba(231, 235, 238, 0.2) inset"}
               
                />
                <ErrorMessage name="title" component="div" className="error-message" />
              </FormControl>

              <FormControl  mt="0.5rem" >
                <FormLabel
                 fontFamily={"Poppins"}
                 fontWeight={500}
                 color="rgba(0, 0, 0, 1)"
                 fontSize={"0.7rem"}
                >Task Description</FormLabel>
                <Input
                  type="text"
                  name="description"
                  borderRadius="2px"
                  fontFamily={"Poppins"}
                  fontWeight={400}
                  color="rgba(65, 77, 99, 1)"
                  fontSize={"0.7rem"}
                  border="1px solid rgba(207, 217, 224, 1)"
                  boxShadow={"0px 2px 0px 0px rgba(231, 235, 238, 0.2) inset"}
               
                />
                <ErrorMessage name="description" component="div" className="error-message" />
              </FormControl>


              {subTasks.map((task, index) => (
                    <Flex mt="1rem" alignItems="center" justifyContent="space-between">
                      <Box display="flex">
                        {" "}
                        <Checkbox checked={task.checkboxValue}  />
                        <Text
                         
                          fontSize={"0.7rem"}
                          fontFamily="poppins"
                          fontWeight={500}
                color="rgba(17, 27, 43, 1)"
                        >
                          {task.description}
                        </Text>
                      </Box>
                      <Box onClick={() => handleRemoveItem(index)}>
                        <RxCross1 />
                      </Box>
                    </Flex>
                  ))}

{subOpen ? (
                <>
                  <Flex mt="1rem">
                    {/* <Checkbox   /> */}
                    <Input
                   
                      type="text"
                      value={description}
                      onChange={handleInputChange}
                   
                      borderRadius="2px"
                      fontFamily={"Poppins"}
                      fontWeight={400}
                      color="rgba(65, 77, 99, 1)"
                      fontSize={"0.7rem"}
                      border="1px solid rgba(207, 217, 224, 1)"
                      boxShadow={"0px 2px 0px 0px rgba(231, 235, 238, 0.2) inset"}
                   
                   />
                  </Flex>

                  <Flex justifyContent="flex-end" gap="1rem" mt="1rem">
                    <Button
                      bg="white"
                      fontSize="0.9rem"
                      color="rgba(51, 51, 51, 0.5)"
                      onClick={() => isSubOpen(false)}
                    >
                      Discard
                    </Button>
                    <Button
                      color="rgba(42, 126, 202, 1)"
                      bg="white"
                      fontSize="0.9rem"
                      onClick={handleSaveTask}
                    >
                      Save task
                    </Button>
                  </Flex>
                </>
              ) : (
                <Button
                  w="full"
                  borderRadius={"2.98px"}
                  display="flex"
                  gap="0.5rem"
                  mt="0.7rem"
                  bg="#633BBC40"
                  fontSize={"0.8rem"}
                  color="#8A6CCD"
                  fontFamily="poppins"
                  _hover={{bg:'rgba(99, 59, 188, 0.25)'}}
                  onClick={() => isSubOpen(true)}
                >
                  <Text>
                  Subtask 

                  </Text>
                  <HiOutlinePlusSmall fontSize={"0.8rem"} fontWeight={600} />

                </Button>
              )}
          </Box>

          {/* Box 2 --------------------------- */}
          <Box 
            p="2rem"
            w="50%"
            boxShadow='0px 3px 10px 0px rgba(0, 0, 0, 0.12)'
            bg="rgba(255, 255, 255, 1)"
  
          >
         <FormControl>
        <FormLabel  fontSize={"0.7rem"}
                          fontFamily={"Poppins"}
                          fontWeight={500}
                          color="rgba(17, 27, 43, 1)">Select Department</FormLabel>
        <Field name="selectedDepartments">
          {({ field, form }) => (
            <Select
              {...field}
              isMulti
              options={departments?.map((dep) => ({
                label: dep.name,
              }))}          
                  onChange={(selectedOptions) => {
                form.setFieldValue('selectedDepartments', selectedOptions.label);
              }}
              value={field.value}
              borderRadius="2px"
              fontFamily={"Poppins"}
              fontWeight={400}
              color="rgba(65, 77, 99, 1)"
              fontSize={"0.7rem"}
              border="1px solid rgba(207, 217, 224, 1)"
              boxShadow={"0px 2px 0px 0px rgba(231, 235, 238, 0.2) inset"}
          
            />
          )}
        </Field>
        <ErrorMessage name="selectedDepartments" component="div" className="error-message"/>

      </FormControl>
   
      <FormControl>
      <FormLabel  fontSize={"0.7rem"}
                          fontFamily={"Poppins"}
                          fontWeight={500}
                          color="rgba(17, 27, 43, 1)">Assign To</FormLabel>
      <Field name="participants">
        {({ field, form }) => (
          <Select
            {...field}
            isMulti
            options={users?.map((user) => ({
              label: `${user.firstname} ${user.lastname}`,
              value: user._id, // or any other unique identifier
            }))}
            onChange={(selectedOptions) => {
              form.setFieldValue('participants', selectedOptions);
            }}
            value={field.value}
            borderRadius="2px"
            fontFamily={"Poppins"}
            fontWeight={400}
            color="rgba(65, 77, 99, 1)"
            fontSize={"0.7rem"}
            border="1px solid rgba(207, 217, 224, 1)"
            boxShadow={"0px 2px 0px 0px rgba(231, 235, 238, 0.2) inset"}
        
          />
        )}
      </Field>
      <ErrorMessage name="participants">
        {(msg) => (
          <Text color="red" fontSize="0.7rem">
            {msg}
          </Text>
        )}
      </ErrorMessage>
    </FormControl>

              <Checkbox
                value={assignToMe}
                onChange={(e) => setAssignToMe(e.target.value)}
              >
                <Text
                  fontFamily={"Poppins"}
                  fontWeight={400}
                  color="rgba(65, 77, 99, 1)"
                  fontSize={"0.6rem"} >
                  Assgin this task to myself
                </Text>
              </Checkbox>

              <Box  mt="1.7rem">
  <FormControl>
    <FormLabel   fontFamily={"Poppins"}
                 fontWeight={500}
                 color="rgba(0, 0, 0, 1)"
                 fontSize={"0.7rem"}>
      Set Priority
    </FormLabel>
    <Field name="priority">
      {({ field, form }) => (
        <RadioGroup
          {...field}
          onChange={(value) => {
            console.log("Value changed:", value);
            form.setFieldValue("priority", value);
          }}
        >
          <Stack spacing={4} direction="row">
            <Radio value="low">
              <Text fontSize="0.6rem" fontFamily="poppins">
                Low
              </Text>
            </Radio>
            <Radio value="mid">
              <Text fontSize="0.6rem" fontFamily="poppins">
                Medium
              </Text>
            </Radio>
            <Radio value="high">
              <Text fontSize="0.6rem" fontFamily="poppins">
                High
              </Text>
            </Radio>
          </Stack>
        </RadioGroup>
      )}
    </Field>
  </FormControl>

  <ErrorMessage name="priority" component="div" className="error" />
</Box>

{/* Add attachment */}
<Box >
              <Text ml="1rem"
              mb="1rem"
              fontWeight={500}
              mt="0.5rem"
              fontSize={"0.7rem"} fontFamily="poppins">
                Add Attachment
              </Text>
              <Box ml="1rem" mr="1rem" mb="1rem" >
                <Input
                h="2rem"
                  type="file"
                  onChange={(e) => setFiles(e.target.files)}
                  fontSize="0.7rem"
                  multiple
                />
              </Box>
              {/* file upload */}
              <Box ml="1rem" mr="1rem">
                <ImageUpload onChange={() => {}} />
              </Box>

              {/* button */}
              <Box py={"1rem"} display="flex" justifyContent="flex-end">
                <Stack direction="row" spacing={4}>
                  <Button
                    colorScheme="teal"
                    variant="outline"
                    fontSize={"0.7rem"}
                  >
                    Cancel
                  </Button>

                  <Button
                  _hover={{bg:'rgba(99, 59, 188, 0.7)'}}
                  fontSize={"0.7rem"}
                  bg="rgba(99, 59, 188, 1)"
                  variant="solid" type="submit">
                    Schedule task
                  </Button>
                </Stack>
              </Box>
            </Box>





          </Box>
        </Flex>
  </Form>
    </Formik>
    </>
  )
}

export default ScheduleTask