import { createSlice } from '@reduxjs/toolkit' //next js redux toolkit  

export const redirectSlice = createSlice({
    name: 'redirect',
    initialState: {
        segment:'Home'
    },
    reducers: {
        update: (state, action) => {
            console.log(action.payload.segment)
            state.segment = action.payload.segment
        }
    }
})
// case under reducers becomes an action 
export const {  update } = redirectSlice.actions
export default redirectSlice.reducer 