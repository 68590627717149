import React, { useState } from "react";
import { Flex, Text, IconButton, Box } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

const generateCalendar = (year, month) => {
  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);
  const daysInMonth = lastDay.getDate();
  const startDay = firstDay.getDay();

  const calendar = [];
  let dayCounter = 1;

  for (let i = 0; i < 6; i++) {
    const week = [];
    for (let j = 0; j < 7; j++) {
      if ((i === 0 && j < startDay) || dayCounter > daysInMonth) {
        week.push(null);
      } else {
        week.push(dayCounter);
        dayCounter++;
      }
    }
    calendar.push(week);
    if (dayCounter > daysInMonth) {
      break;
    }
  }

  return calendar;
};

const BigCalendar = ({ eventData,year,month,setYear,setMonth}) => {

  const currentDate = new Date();
  const calendar = generateCalendar(year, month);

  const handlePrevMonth = () => {
    setMonth((prevMonth) => (prevMonth === 0 ? 11 : prevMonth - 1));
    setYear((prevYear) => (month === 0 ? prevYear - 1 : prevYear));
  };

  const handleNextMonth = () => {
    setMonth((prevMonth) => (prevMonth === 11 ? 0 : prevMonth + 1));
    setYear((prevYear) => (month === 11 ? prevYear + 1 : prevYear));
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", {
    month: "long",
    year: "numeric",
  }).format(new Date(year, month));

 
  return (
    <Flex direction="column" align="center" w="full">
      <Flex align="center" mb={3}  justify="space-between"  w="90%">
        <Box/>
        <Box display="flex" alignItems="center" justifyContent="center">
          <IconButton
           w="1rem"
           h="1rem"
           p="1rem"
            icon={<ChevronLeftIcon />}
            onClick={handlePrevMonth}
            aria-label="Previous Month"
            variant="outline"
          />
          <Text fontWeight={500} fontFamily="poppins" fontSize="0.7rem" mx={4}>
            {formattedDate}
          </Text>
          <IconButton
          w="1rem"
          h="1rem"
          p="1rem"

            icon={<ChevronRightIcon />}
            onClick={handleNextMonth}
            aria-label="Next Month"
            variant="outline"
          />
        </Box>
       <Link to='/editcalender'> <Button color={"#FFFFFF"} bg={"#195387"} fontFamily={"Poppins"} fontSize={"0.8rem"} h="2rem" _hover={{
                bg:"#195387"
              }} >Edit Holidays</Button></Link>
      </Flex>
      <Flex direction="column" align="center" w="90%" >
        <Flex borderColor="gray.100" w="full">
          {daysOfWeek.map((day) => (
            <Text
              key={day}
              //   mx={2}
              p={2}
              fontFamily="poppins"
              border="0.1px solid"
              borderColor="gray.100"
              w={`${100 / 7}%`}
              textAlign="center"
              bgColor={day === "SUN" ? "#FFEBEB" : "transparent"}
              color={day === "SUN" ? "#E92C2C" : "inherit"}
            >
              {day}
            </Text>
          ))}
        </Flex>
        {calendar.map((week, index) => (
          <Flex key={index} justify="space-around" w="full">
            {week.map((day, dayIndex) => {
              const eventForDay = eventData?.find((event) => {
                const eventStartDate = new Date(event.from);
                const eventEndDate = new Date(event.to);

                const currentDate = new Date(Date.UTC(year, month, day));

                const isWithinRange =
                  currentDate >= eventStartDate && currentDate <= eventEndDate;

                const isSameDay =
                  currentDate.toISOString().split("T")[0] ===
                    eventStartDate.toISOString().split("T")[0] ||
                  currentDate.toISOString().split("T")[0] ===
                    eventEndDate.toISOString().split("T")[0];

                return isWithinRange || isSameDay;
              });

              return (
                <Box
                  key={dayIndex}
                  position="relative"
                  p={7}
                  bg={
                    day !== null && day === currentDate.getDate() && month === currentDate.getMonth() 
                      ? "gray.50"
                      : "transparent"
                  }
                  textAlign="center"
                  border="0.0001px solid"  
                  borderColor="gray.100"
                  w={`${100 / 7}%`}
                >
                  {day !== null && (
                    <Text
                      position="absolute"
                      top="0"
                      right="0.4rem"
                      fontSize="1rem"
                    >
                      {day}
                    </Text>
                  )}
                  <Text
                    color="#E92C2C"
                    pl="0.1rem"
                    fontFamily="poppins"
                    textAlign="left"
                    bg="#FFEBEB"
                    fontSize="0.7rem"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    position="absolute"
                    bottom="0.4rem"
                    left="0.4rem"
                  >
                    {eventForDay
                      ? eventForDay.name.length > 10
                        ? `${eventForDay.name.slice(0, 10)}...`
                        : eventForDay.name
                      : ""}
                  </Text>
                </Box>
              );
            })}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default BigCalendar;
