import React, { useState } from 'react';
import { Document, Page ,pdfjs} from 'react-pdf';
// import 'pdfjs-dist/web/pdf_viewer.css';
// import 'pdfjs-dist/web/pdf_viewer.css';


function PDFViewer({ url}) {
    url = decodeURIComponent(url)
    console.log(url,'pdfurl')
    
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const isImageUrlOrPdf = (url) => {
        const allowedExtensions = ['pdf','jpg', 'jpeg', 'png', 'gif', 'webp' ];
        const extension = url.split('.').pop().toLowerCase();
        return allowedExtensions.includes(extension);
    };

    
    // if (isImageUrlOrPdf(url)) {
        // If it's an image or PDF, render it accordingly
        if (url.toLowerCase().includes('pdf')) {
            // If it's a PDF, render the PDF component
            return (
                <div>
                    <Document file={url} onLoadSuccess={onDocumentLoadSuccess} >
                        {Array.from({ length: numPages }, (_, i) => (
                            <Page key={`page_${i + 1}`} pageNumber={i + 1} />
                        ))}
                    </Document>
                </div>
            );
        } else {
            // If it's an image, render the image component
            return <img src={url} alt="Image" />;
        }
    } 
// }

export default PDFViewer;
