import React from 'react';
import { Document, Page } from 'react-pdf';

function PDFViewerComponent({ pdfFile }) {
  return (
    <Document file={pdfFile}>
      <Page pageNumber={1} />
    </Document>
  );
}

export default PDFViewerComponent;
