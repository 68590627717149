import { Circle, Flex, Box, Divider, Text, SimpleGrid } from "@chakra-ui/react";
import React, { useEffect } from "react";

function About({data}) {
console.log(data,'dataaaaaaaaaaa')
  return (
    <SimpleGrid columns={{ sm: 2, md: 2, lg: 3, xl: 4, "2xl": 4 }} justifyContent="space-between" align="center">
      <AnimatedLeftToRight
        heading="Summary"
        data={data?.summary} 
      />
      <Box>
        <AnimatedLeftToRight heading="Father’s Name" data={data?.fathersName} />
        <AnimatedLeftToRight heading="Country" data={data?.country} />
        <AnimatedLeftToRight
          heading="Emergency Phone Number"
          data={`+91 ${data?.phone}`}
        />
      </Box>
      <Box>
        <AnimatedLeftToRight
          heading="Address"
          data={data?.address}
        />

        <AnimatedLeftToRight
          heading="Language"
          data={data?.language?.length > 0? data?.language?.map((item)=>item) : "-"}  
        />

      </Box>
      <Box>
        <AnimatedLeftToRight heading="Age"
        data={data?.age ? data.age : ['-']}/>
        <AnimatedLeftToRight heading="Gender" data={data?.gender} />
        <AnimatedLeftToRight heading="Blood Group" data={data?.bloodGroup}/>
      </Box>
    </SimpleGrid>
  );
}

const AnimatedLeftToRight = (props) => {
  const { heading, data } = props;
  return (
    <Flex mt="1rem" ml="1rem">
      <Box>
        <Circle h="0.8rem" w="0.8rem" bg="#B288E5" mr={"0.6rem"}  />
        <Divider
          h="1.6rem"
          orientation="vertical"
          ml="0.46rem"
          borderLeft="2px solid #B288E5"
         
        />
        <Divider ml="0.4rem" width="1.2rem" borderBottom="2px solid #B288E5" />
      </Box>
      <Box>
        <Text fontFamily="poppins" ml="0.2rem">
          {heading}
        </Text>
        <Box
          maxW="18vw"
          minW="12rem"
          px="0.9rem"
          fontWeight={500}
          py="0.4rem"
          borderRadius="0.5rem"
          fontFamily="poppins"
          fontSize={{
            base: "0.8rem",
            md: "0.7rem",
            lg: "0.8rem",
            xl: "0.8rem",
            "2xl": "0.9rem",
          }}
          className="animated-bg" // Apply the CSS class for animation
        >
          {data ? data : "_"}{" "}
        </Box>
      </Box>
    </Flex>
  );
};

export default About;