// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "ethnocentric", "Roboto","Poppins","Montserrat"
    sans-serif;
  font-family: "Montserrat", sans-serif;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT;cACY;EACZ,qCAAqC;EACrC,kCAAkC;EAClC,mCAAmC;EACnC,kCAAkC;AACpC","sourcesContent":["body {\n  overflow-x: hidden;\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"ethnocentric\", \"Roboto\",\"Poppins\",\"Montserrat\"\n    sans-serif;\n  font-family: \"Montserrat\", sans-serif;\n  font-family: \"Poppins\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
