// userActions.js
export const setUser = (userData) => ({
    type: 'SET_USER',
    payload: userData,
  });
  
  // userReducer.js
  const initialState = {
    user: null,
  };
  
  const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_USER':
        return {
          ...state,
          user: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default userReducer;
  