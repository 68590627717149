import { createSlice } from '@reduxjs/toolkit' //next js redux toolkit  

export const signupSlice = createSlice({
    name: 'signup',
    initialState: {
        step:5,
        id:'',
        firstname:'',
        lastname:'',
        email:''
    },
    reducers: {
        update: state => {
            state.step = JSON.parse(localStorage.getItem('step'))
            state.id = JSON.parse(localStorage.getItem('id'))
            state.firstname = JSON.parse(localStorage.getItem('firstname'))
            state.lastname = JSON.parse(localStorage.getItem('lastname'))
            state.email = JSON.parse(localStorage.getItem('email'))
        }
    }
})
// case under reducers becomes an action 
export const {  update } = signupSlice.actions
export default signupSlice.reducer 