import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Flex, SimpleGrid, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import LeaveCard from './LeaveCard'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Header from './Heading';
import { IoIosArrowForward } from 'react-icons/io';
import { MdOutlineRestore } from 'react-icons/md';
import Lottie from 'lottie-react';
import animationData from "../../assets/EbNsOV437i.json";

function LeaveHistory() {
    const location = useLocation();
    const { onLeave, setOnLeave, leaveRequest, setLeaveRequest } = location.state || {};
  console.log(leaveRequest,'leaveRequesttttttt')
    const [history, setHistory] = useState()
    const navigate = useNavigate();
    // const [edit, setEdit] = useState(false)

    const baseURL = process.env.REACT_APP_URL;
  
    const [loading, isLoading] = useState(false);
    const [submitting, isSubmitting] = useState(false);
  

    const [leaveData, setleaveData] = useState('');
const [onLeaveData, setOnLeaveData] = useState('')
    useEffect(() => {
      if (setLeaveRequest || setOnLeave) {
        setleaveData(setLeaveRequest);
        setOnLeaveData(setOnLeave)
      }
    }, [setleaveData,setOnLeaveData])

    
    useEffect(() => {
        isLoading(true);
    
        axios
          .get(`${baseURL}/teamConnect/leave_requests`, {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("user")).token,
            },
          })
          .then((res) => {
            isLoading(false);
            console.log(res.data, "response");
            setHistory(res?.data?.historyData);
          })
          .catch((err) => {
            isLoading(false);
            if (err?.response?.message) {
              toast.error(err?.response?.message, {
                position: "bottom-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else if (err?.response?.data?.payment === false) {
              Navigate("/payment");
            }
          });
      }, []);

      const handleApprove = (status, id) => {
        const iD = id;
        isSubmitting(true)
        // isLoading(true);
        axios
          .post(
            `${baseURL}/teamConnect/leavedecision`,
            { approvelStatus: status, leave_id: id },
            {
              headers: {
                Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem("user")).token,
              },
            }
          )
          .then((res) => {
            console.log(res,'acceptrejectttttt')
            isSubmitting(false)
            toast.info(res.data.message, {
              position: "bottom-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
      
            if (status === "approved") {
                // setEdit(false);
                setHistory((prevHistory) => {
                    return history?.map(leave => {
                      if (leave?._id === res?.data?.Data?._id) {
                        leave = res?.data?.Data;
                      }
                      return leave;
                    });
                  });
              } else if (status === "rejected") {
                setHistory((prevHistory) => {
                  return history?.map(leave => {
                    if (leave?._id === res?.data?.Data?._id) {
                      leave = res?.data?.Data;
                    }
                    return leave;
                  });
                });
              }
            // isLoading(false);
    
          })
          
          .catch((err) => {
            // isLoading(false);
            if (err?.response?.message) {
              toast.error(err?.response?.message, {
                position: "bottom-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            } else if (err?.response?.data?.payment === false) {
              navigate("/payment");
            }
          });
      };

      if (loading)
return (
  <Flex
    maxW="10rem"
    mt="24vh"
    ml="36vw"
    alignItems="center"
    justifyContent="center"
  >
    <Lottie animationData={animationData} loop autoplay />
  </Flex>
);
  return (
    <Box>
         <Header/>
    <Box p='1rem 0 1rem 0' display='flex' justifyContent={'space-between'}>
    <Breadcrumb  pl={0} color="rgba(51, 51, 51, 0.5)" fontFamily={'Poppins'} fontWeight={500} fontSize={'1rem'} spacing='8px' separator={<IoIosArrowForward  color='gray.500' />}>
  <BreadcrumbItem >
    <BreadcrumbLink href='/leave'>Leaves</BreadcrumbLink>
  </BreadcrumbItem>
{leaveRequest.length !==0 &&
  <BreadcrumbItem >
    <BreadcrumbLink href='/leaverequest'>Leave request</BreadcrumbLink>
  </BreadcrumbItem>
}
  <BreadcrumbItem isCurrentPage>
    <BreadcrumbLink href='#'>History</BreadcrumbLink>
  </BreadcrumbItem>
</Breadcrumb>



    </Box>
        <SimpleGrid columns={[1,1,2,2,3,4]}  gap="2rem">
            {
                history?.map((data,index)=>{
                    return(
                        <LeaveCard
                        
                        data={data}
                        index={index}
                        handleApprove={handleApprove}
                        submitting={submitting}
                        
                        />

                    )
                })
            }

        </SimpleGrid>

    </Box>


  )
}

export default LeaveHistory