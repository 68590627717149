import { Flex,Box,Text, SimpleGrid} from '@chakra-ui/react'
import React,{useState,useEffect} from 'react'
import moment from 'moment'
function Job({data}) {
  console.log(data,'daaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaataaaaaaaaaaa')
    const [isGradient, setIsGradient] = useState(false);

    useEffect(() => {
      const interval = setInterval(() => {
        setIsGradient((prevIsGradient) => !prevIsGradient);
      }, 1000);
  
      return () => {
        clearInterval(interval);
      };
    }, []);

  return (
    <SimpleGrid columns={{sm: 2, md: 2, lg: 3,xl:5,'2xl':5}}>
     <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      p="1rem"
      mr="2.5rem"
      bg={isGradient ? `linear-gradient(90deg, rgba(152, 96, 220, 0.4) 0%, rgba(189, 115, 227, 0.4) 75.4%, rgba(181, 111, 225, 0.4) 100%)`: "#F9F9FB"}
      borderRadius="1rem"
      h="35vh"
      m="1.2rem"
      maxH="15rem"
      w="11vw"
      minW="12rem"
      fontSize="1.5rem"
      fontWeight={600}  
      fontFamily="poppins"
      transform={isGradient ? "rotate(0deg)" : "rotate(0)"}
      transformOrigin="center"
      transition="background 0.5s, transform 0.5s"
    >
      {/* <Text textAlign="center">UI UX Designer</Text> */}
      <Box
      className="behind-box"
        position="absolute"
        top="50%"
        left="50%"
        transform={`translate(-50%, -50%) rotate(${isGradient ? 20 : 0}deg)`}
        display="flex"
        justifyContent="center"
        alignItems="center"
        mr="2.5rem"
        p="1rem"
        bg={isGradient ? " linear-gradient(90deg, #9860DC 0%, #BD73E3 75.4%, #B56FE1 100%)" : "#F9F9FB"}
        borderRadius="1rem"
        h="35vh"
        maxH="15rem"
        w="11vw"
        minW="12rem"
        fontSize="1.5rem"
        fontWeight={600}
        fontFamily="poppins"
        transformOrigin="center"
        transition="background 0.5s, transform 0.5s"
      >
         <Text textAlign="center">{data.designation}</Text>
      </Box>
    </Box>
    <Box ml="2rem">
        {/* <Field title="Department" value={data.department}/> */}
        <Field title="Employee ID" value={data.employeId}/>
        <Field title="Joining Date"  value={moment(data.joining).format('DD-MM-YY')}/>
    </Box>

    <Box>
        <Field title="Location" value={data.location}/>
        {/* <Field title="Location Type" value={data.locationType}/>
        <Field title="Employment Type" value={data.employmentType}/> */}
    </Box>

    {/* <Box>
        <Field title="Probation" value={data.probationStart}/>
        <Field title="to" value={data.probationEnd}/>
        <Field title="Full-time" value={data.shiftType}/>
    </Box> */}
    {/* <Box>
        <Field title="Hr" value={data.hr}/>
        <Field title="Lead" value={data.teamlead}/>
    </Box> */}
    </SimpleGrid>
  )
}


const Field=(props)=>{
    const {title,value}=props
    return(
        <Box mt="1rem">
            <Text color="#333333" fontFamily="poppins"   fontSize={{
            base: "0.8rem",
            md: "0.7rem",
            lg: "0.8rem",
            xl: "0.8rem",
            "2xl": "0.9rem",
          }} opacity="60%">{title}</Text>
            <Text  w="12rem" bg="#F9F9FB" textAlign="center" py="0.6rem" px="0.5rem" borderRadius="0.7rem" color="#333333" fontFamily="poppins"   fontSize={{
            base: "0.8rem",
            md: "0.7rem",
            lg: "0.8rem",
            xl: "0.8rem",
            "2xl": "0.9rem",
          }} fontWeight={500}>{value}</Text>
        </Box>
    )
}
export default Job
