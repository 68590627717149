import { Box, Flex, Text, Spacer, Button, Icon, Circle } from '@chakra-ui/react';
import { Link, useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import React from 'react';
import { BackButton, votingArrowLeft } from '../icons/icons';
import { IoMdSettings } from 'react-icons/io';
import { IoClose, IoSettingsSharp } from 'react-icons/io5';

function Header(props) {
  const navigate = useNavigate(); // useNavigate instead of useHistory

  const handleBackButtonClick = () => {
    navigate(-1); // Go back to the previous page
    // navigate('/transactions')
  };
  const location = useLocation();
  const currentPath = location.pathname;
  
   console.log(currentPath,'////////');
  return (
    <Flex p='0.5rem' justifyContent="space-between"  position={'relative'} boxShadow="base">
      <Box display={'flex'} gap='0.5rem' alignItems={'center'} cursor={'pointer'} onClick={handleBackButtonClick}>
        <Icon as={votingArrowLeft}/>

        <Text fontFamily={'Poppins'} fontSize={'1.5rem'} fontWeight={600}>
          {/* {currentPath.includes('/director') ? 'Directors' : (currentPath.includes('/investor') ? 'Investor' : 'Team Connect')} */}

           { currentPath.includes('/director') ? 'Directors' :
            currentPath.includes('/adddirector') ? 'Director' :
            currentPath.includes('/investor') || currentPath.includes('/addinvestor') ? 'Investor' :
            currentPath.includes('/editinvestor') ? 'Investor' :
            currentPath.includes('/editdir') ? 'Director' :
            'Team Connect'}

          
        </Text>
      </Box>
      { props.isMain &&
      <Link to="/teamconnect-settings">
       <Circle 
  display={'flex'} 
  justifyContent={'center'} 
  alignItems={'center'} 
  bg='rgba(3, 94, 167, 0.2)' 
  borderRadius={'50%'} 
  w='2rem' 
  h='2rem'
  style={{ transition: 'transform 0.3s' }} 
  _hover={{ transform: 'rotate(180deg)' }} 
  >
  <IoSettingsSharp fontSize={'1.1rem'} />
</Circle>

      </Link>
      
      }
       
      { currentPath.includes('/teamconnect-settings') &&
      
     <Link to='/employees'>
       <Button
      type="submit"
      borderRadius="2rem"
      color="#E92C2C"
      bg="#FFEBEB"
      fontWeight={500}
      fontFamily="Poppins"
      w="7rem"
      position="relative"
      fontSize="0.7rem"
      display="flex"
      justifyContent="flex-end" // Adjusted to align to the right
      alignItems="center"
    >
      <span>Close</span>
      <Circle
        bg="#E92C2C"
        h="2.5rem"
        w="2.5rem"
        color="white"
        borderLeft="2px solid white"
        display="flex"
        position="relative"
        left="1rem"
        alignItems="center"
        justifyContent="center"
      >
        <IoClose/>
      </Circle>
    </Button>
    </Link>
      }
     
     
    </Flex>
  );
}

export default Header;
