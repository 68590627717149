// OrgSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const orgSlice = createSlice({
  name: 'org',
  initialState: {
    organizationData: {},
  },
  reducers: {
    setOrganizationData: (state, action) => {
      state.organizationData = action.payload;
    },
// OrgSlice.js
updateOrg: (state, action) => {
  try {
    console.log('Payload before update:', state.organizationData);
    state.organizationData = { ...state.organizationData, ...action.payload };
    console.log('Payload after update:', state.organizationData);
  } catch (error) {
    console.error('Error in updateOrg reducer:', error);
  }
},


  },
});

export const { setOrganizationData, updateOrg } = orgSlice.actions;
export default orgSlice.reducer;
