import { Box, Circle, Divider, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import { FaGraduationCap } from "react-icons/fa";
import { GiAchievement } from "react-icons/gi";
import { PiBagSimpleFill } from "react-icons/pi";
import { TbBulbFilled } from "react-icons/tb";

function Profile({ data }) {
  return (
    <SimpleGrid
      gridAutoFlow={"row"}
      gap="2rem"
      columns={[1, 2, 2, 4]}
      ml="4vw"
      fontFamily={"poppins"}
    >
      {console.log(data, "ooooooooooooooooooooooo")}
      <Box>
        <Box
          maxW={"10rem"}
          background="linear-gradient(90deg, rgba(152, 96, 220, 0.75) 0%, rgba(189, 115, 227, 0.75) 75.4%, rgba(181, 111, 225, 0.75) 100%)"
          display="flex"
          alignItems={"center"}
          justifyContent={"space-between"}
          p="10px 20px 10px 20px"
          borderRadius={"10px"}
        >
          <Text fontWeight={600} fontFamily={"poppins"}>
            Experience
          </Text>
          <PiBagSimpleFill fontSize={"1.5rem"} />
        </Box>
        {data.experience?.map((edu, index) => (
          <React.Fragment key={index}>
            {console.log("edu", edu)} {/* Log the edu object to the console */}
            {index === 0 && (
              <AnimatedLeftToRight
                name={edu?.jobTitle}
                date={`${edu?.companyName},${edu?.location}`}
                place={`${edu?.startDate}-${edu?.endDate}`}
              />
            )}
            {index >= 1 && (
              <AnimatedLeftToRightTwo
                name={edu?.jobTitle}
                date={`${edu?.companyName},${edu?.location}`}
                place={`${edu?.startDate}-${edu?.endDate}`}
              />
            )}
          </React.Fragment>
        ))}

        {/* 
        <AnimatedLeftToRightTwo
          name="Qburst Pvt Ltd"
          date="Mar 2021 - Jan 2023"
          place="Kozhikode, Kerala Pvt Ltd"
        /> */}
      </Box>

      <Box>
        <Box
          maxW={"10rem"}
          background="linear-gradient(90deg, rgba(152, 96, 220, 0.75) 0%, rgba(189, 115, 227, 0.75) 75.4%, rgba(181, 111, 225, 0.75) 100%)"
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          p="10px 20px 10px 20px"
          borderRadius={"10px"}
        >
          <Text fontWeight={600} fontFamily={"poppins"}>
            Education
          </Text>
          <FaGraduationCap fontSize={"1.5rem"} />
        </Box>
        {data.education?.map((edu, index) => (
          <React.Fragment key={index}>
            {index === 0 && (
              <AnimatedLeftToRight
                name={edu.qualification}
                date={`${edu.instituteName},${edu.fieldOfStudy}`}
                place={`${edu.startDate}-${edu.endDate}`}
              />
            )}
            {index >= 1 && (
              <AnimatedLeftToRightTwo
                name={edu.qualification}
                date={`${edu.instituteName},${edu.fieldOfStudy}`}
                place={`${edu.startDate}-${edu.endDate}`}
              />
            )}
          </React.Fragment>
        ))}
      </Box>

      <Box>
        <Box
          maxW={"10rem"}
          background="linear-gradient(90deg, rgba(152, 96, 220, 0.75) 0%, rgba(189, 115, 227, 0.75) 75.4%, rgba(181, 111, 225, 0.75) 100%)"
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          p="10px 20px 10px 20px"
          borderRadius={"10px"}
        >
          <Text fontWeight={600} fontFamily={"poppins"}>
            Achievement
          </Text>
          <GiAchievement fontSize={"1.5rem"} />
        </Box>
        {data?.achievements?.map((ach, index) => (
          <React.Fragment key={index}>
            {index === 0 && <AnimatedLeftToRight name={ach.someProperty} />}
            {index >= 1 && <AnimatedLeftToRightTwo name={ach.someProperty} />}
          </React.Fragment>
        ))}
      </Box>

      <Box>
        <Box
          maxW={"10rem"}
          background="linear-gradient(90deg, rgba(152, 96, 220, 0.75) 0%, rgba(189, 115, 227, 0.75) 75.4%, rgba(181, 111, 225, 0.75) 100%)"
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          p="10px 20px 10px 20px"
          borderRadius={"10px"}
        >
          <Text fontWeight={600} fontFamily={"poppins"}>
            Skills
          </Text>
          <TbBulbFilled fontSize={"1.5rem"} />
        </Box>
        {data.skills?.map((skill, index) => (
          <React.Fragment key={index}>
            {index === 0 && <AnimatedLeftToRight name={skill} />}
            {index >= 1 && <AnimatedLeftToRightTwo name={skill} />}
          </React.Fragment>
        ))}
      </Box>
    </SimpleGrid>
  );
}

const AnimatedLeftToRight = (props) => {
  const { heading, data, name, date, place } = props;
  return (
    <Flex>
      <Box ml="1rem">
        <Circle
          h="0.8rem"
          w="0.8rem"
          bg="#F7F7F7"
          mt="-0.4rem"
          border="1px solid #9860dc"
          zIndex="1"
        />
        <Divider
          h="2rem"
          orientation="vertical"
          ml="0.3rem"
          className="animated-text"
          borderLeft="2px solid #B288E5"
        />
        <Divider
          ml="0.4rem"
          width="1.2rem"
          borderBottom="2px solid #B288E5"
          className="animated-text"
        />
      </Box>

      <Box
        mt="1rem"
        minW="14vw"
        px="0.9rem"
        fontWeight={500}
        py="0.4rem"
        borderRadius="0.5rem"
        fontFamily="poppins"
        fontSize={{
          base: "0.8rem",
          md: "0.7rem",
          lg: "0.8rem",
          xl: "0.8rem",
          "2xl": "0.9rem",
        }}
        className="animated-box"
      >
        <Text
          fontFamily={"poppins"}
          fontWeight="600"
          fontSize={{
            base: "0.8rem",
            md: "0.7rem",
            lg: "0.8rem",
            xl: "0.8rem",
            "2xl": "0.9rem",
          }}
          className="animated-text"
        >
          {name}
        </Text>
        <Text
          fontFamily={"poppins"}
          fontSize={{
            base: "0.8rem",
            md: "0.7rem",
            lg: "0.8rem",
            xl: "0.8rem",
            "2xl": "0.9rem",
          }}
          className="animated-text"
        >
          {date}
        </Text>
        <Text
          fontFamily={"poppins"}
          fontSize={{
            base: "0.8rem",
            md: "0.7rem",
            lg: "0.8rem",
            xl: "0.8rem",
            "2xl": "0.9rem",
          }}
          className="animated-text"
        >
          {place}
        </Text>
      </Box>
    </Flex>
  );
};

const AnimatedLeftToRightTwo = (props) => {
  const { heading, data, name, date, place } = props;
  return (
    <Flex>
      <Box ml="1rem">
        <Divider
          h="6.4rem"
          mt="-4.4rem"
          orientation="vertical"
          ml="0.3rem"
          borderLeft="2px solid #B288E5"
          className="animated-text"
          zIndex="0"
        />
        <Divider ml="0.4rem" width="1.2rem" borderBottom="2px solid #B288E5" />
      </Box>
      <Box>
        <Text
          fontFamily="poppins"
          ml="0.2rem"
          fontSize={{
            base: "0.8rem",
            md: "0.7rem",
            lg: "0.8rem",
            xl: "0.8rem",
            "2xl": "0.9rem",
          }}
        >
          {heading}
        </Text>
        <Box
          mt="1rem"
          minW="14vw"
          px="0.9rem"
          fontWeight={500}
          py="0.4rem"
          borderRadius="0.5rem"
          fontFamily="poppins"
          fontSize="0.9rem"
          className="animated-box" // Apply the CSS class for animation
        >
          <Text
            fontFamily={"poppins"}
            fontWeight="600"
            fontSize={{
              base: "0.8rem",
              md: "0.7rem",
              lg: "0.8rem",
              xl: "0.8rem",
              "2xl": "0.9rem",
            }}
            className="animated-text"
          >
            {name}
          </Text>
          <Text
            fontFamily={"poppins"}
            fontSize={{
              base: "0.8rem",
              md: "0.7rem",
              lg: "0.8rem",
              xl: "0.8rem",
              "2xl": "0.9rem",
            }}
            className="animated-text"
          >
            {date}
          </Text>
          <Text
            fontFamily={"poppins"}
            fontSize={{
              base: "0.8rem",
              md: "0.7rem",
              lg: "0.8rem",
              xl: "0.8rem",
              "2xl": "0.9rem",
            }}
            className="animated-text"
          >
            {place}
          </Text>
        </Box>
      </Box>
    </Flex>
  );
};

export default Profile;
