import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import Header from "./Heading";
import { IoIosArrowForward } from "react-icons/io";
import "react-big-calendar/lib/css/react-big-calendar.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { AiFillCloseCircle } from "react-icons/ai";
import BigCalendar from "./BigCalender";
import BigCalendar2 from "./BigCalender2";

function Calender() {
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_URL;
  const currentDate = new Date();
  const [year, setYear] = useState(currentDate.getFullYear());
  const [month, setMonth] = useState(currentDate.getMonth());

  const [events, setEvents] = useState([]);
  useEffect(() => {

      const formattedDate = moment({ year, month });
    const formattedMonth = formattedDate.format("MMMM");

    axios
      .get(`${baseURL}/teamConnect/leavecalendar/${formattedMonth}/${year}`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).token,
        },
      })
      .then((res) => {
        console.log(res.data,'ooooooooooooooooooooo');
    
        const leaveDetails = res.data?.leaveDetail?.map((leave) => ({
          leaveType: leave.leaveType,
          name: leave.staff.firstname,
          from:leave.from,
          to:leave.to,
          type:"leave"
        }));
        const eventDetails = res.data?.evnt?.map((event) => ({
          name: event.title,
          from:event.date?.split('T')[0],
          to:event.date?.split('T')[0],
          type:"event"
        }));
        console.log(eventDetails,'rrrrrrrr');
        const mergedEvents = [...events, ...(leaveDetails || []), ...(eventDetails || [])];
        setEvents(mergedEvents);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.message) {
           toast.error(err.response.data.message, {
            position: "bottom-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });;
        } else if (err.response.data.payment === false) {
          navigate("/payment");
        }
      });
  }, [year,month]);


  return (
    <Box>
      
      <Header />
      <Box p="1rem 0 1rem 0" display="flex" justifyContent={"space-between"}>
        <Breadcrumb
          pl={0}
          color="rgba(51, 51, 51, 0.5)"
          fontFamily={"Poppins"}
          fontWeight={500}
          fontSize={"1rem"}
          spacing="8px"
          separator={<IoIosArrowForward color="gray.500" />}
        >
          {/* <BreadcrumbItem>
            <BreadcrumbLink href="/leave">Leaves</BreadcrumbLink>
          </BreadcrumbItem> */}

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href="#">Calendar</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Box>
      <BigCalendar2 eventData={events} year={year} month={month} setYear={setYear} setMonth={setMonth}/>
    </Box>
  );
}

export default Calender;
