import {
    Box,
    Button,
    Center,
    Circle,
    Divider,
    Flex,
    Image,
    Text,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    TabPanel,
    Textarea,
  } from "@chakra-ui/react";
  import React from "react";
  import { BackButton } from "../icons/icons";
  import { IoChevronBackSharp } from "react-icons/io5";
  import Feature2 from "../../assets/user (2).webp";
  import { MdOutlineMailOutline } from "react-icons/md";
  import { FiSmartphone } from "react-icons/fi";
  import { FaMicrophoneLines } from "react-icons/fa6";
  import { PiCakeFill } from "react-icons/pi";
  import { FaRegCopy } from "react-icons/fa";
  import { GoArrowUpRight } from "react-icons/go";
  import About from "./About";
  import Profile from "./Profile";
  import Job from "./Job";
  import Payroll from "./Payroll";
  import Documents from "./Documents";
  import Header from "../Team connect/Heading";
  import { Link, useNavigate, useParams } from "react-router-dom";
  import { useState } from "react";
  import { ToastContainer, toast } from 'react-toastify'
  import 'react-toastify/dist/ReactToastify.css';
  import { IoIosCloseCircleOutline } from "react-icons/io";
  import { AiFillCloseCircle } from "react-icons/ai";
  import { useEffect } from "react";
  import axios from "axios";
  import  Lottie  from 'lottie-react';
  import animationData from '../../assets/EbNsOV437i.json';
  import Terminate from '../../assets/terminate.png'
import moment from "moment";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { MdOutlineContentCopy } from "react-icons/md";
import * as Yup from 'yup';
  
  function DirectorProfile() {
    const baseURL = process.env.REACT_APP_URL;
    const user = JSON.parse(localStorage.getItem("user"));
    const JWTtoken = user.token;
    const navigate = useNavigate();
    const [director, setDirector] = useState([]);
    const [loading, isLoading] = useState(false);
   const {id}=useParams() 
    
    useEffect(() => {
      isLoading(true);
      axios
        .get(`${baseURL}/director/director/${id}`, {
          headers: { Authorization: "Bearer " + JWTtoken },
        })
        .then((res) => {
          console.log(res.data,'responseeeeeeeeeeeeee');
          setDirector(res?.data);
          isLoading(false);
        })
        .catch((err) => {
          isLoading(false);
          if (err.response.data.message) {
             toast.error(err.response.data.message, {
              position: "bottom-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });;
  
             
          } else if (err.response.data.payment == false) {
            navigate("/payment");
          }
        });

       
        
    }, []);
    
    const validationSchema=Yup.object().shape({
      reason: Yup.string()
      .trim()
      .min(5, 'Reason should be at least 5 characters')
      .max(200, 'Reason should not exceed 200 characters')
      .required('Reason is required'),
    })

  
    const handleEdit=(dirId)=>{
      console.log(dirId,'director ........... id ...............')
     navigate(`/editdir/${dirId}`)
    }
    const [modal,setModal]=useState(false)
    const [reason,setReason]=useState('')
    const [submitting,isSubmitting]=useState(false)
  
    
  
    if(loading)return(
      <Flex maxW="10rem" mt="24vh" ml="36vw" alignItems="center" justifyContent="center">
    <Lottie
    animationData={animationData}
    loop
    autoplay
  /></Flex> 
    )


  
    return (
      <>
        {/* <Box display={"flex"} cursor={"pointer"} boxShadow="base"> */}
          <Header/>
        {/* </Box> */}
        <Box
          display={"flex"}
          cursor={"pointer"}
          my="1rem"
          justifyContent={"space-between"}
        >
          <Box
            mt={"0.6rem"}
            display={"flex"}
            justifyContent="center"
            alignItems="center"
          >
            <IoChevronBackSharp />
            <Text
              ml={"5px"}
              fontFamily={"Poppins"}
              fontSize={18}
              color={"#0C2242"}
              _dark={{ color: "white" }}
            >
              Director Profile
            </Text>{" "}
          </Box>
          <Box display="flex">
            <Button
              bg="#2A7ECA"
              color="white"
              _hover={{
                bg:"#2A7ECA"
              }}
              borderRadius="1.5rem"
              fontFamily="poppins"
              fontSize="0.9rem"
              onClick={()=>setModal(true)}
  
            >
             Remove Access
            </Button>
            <Button
              ml="0.7rem"
              bg="#2A7ECA"
              _hover={{
                bg:"#2A7ECA"
              }}
              color="white"
              borderRadius="1.5rem"
              fontFamily="poppins"
              fontSize="0.9rem"
              onClick={() => handleEdit(director._id)}
            >
              Edit Profile
            </Button>
          </Box>
        </Box>
  
        <Box
          display="flex"
          alignItems="center"
          flexDir={{ base: "row", md: "column", lg: "row", xl: "row" }}
          w="100%"
          gap={"2rem"}
        >
          {/* /profile */}
          <Box
            ml={"0.8rem"}
            w="70%"
            display="flex"
            py="1rem"
            borderRadius="1rem"
            minW="28rem"
            h="30vh"
            bg="linear-gradient(90deg, rgba(152, 96, 220, 0.75) 0%, rgba(189, 115, 227, 0.75) 75.4%, rgba(181, 111, 225, 0.75) 100%)"
            justifyContent="center"
            alignItems="center"
          >
            <Image
              align="center"
              src={director?.basicInfo?.profilePicture}
              h="6rem"
              w="6rem"
              borderRadius="50%"
              mr="2rem"    
            />
            <Box>
              <Text fontSize="1.2rem" fontWeight={600} fontFamily="poppins">
                {director?.basicInfo?.firstname}  {director?.basicInfo?.lastname}
              </Text>
              <Text fontSize="0.9rem" fontFamily="poppins">
              {director?.basicInfo?.jobPosition}
              </Text>
              <Text fontSize="0.9rem" fontFamily="poppins" mt="0.4rem">
                ID -  {director?.basicInfo?.employeeId}
              </Text>
              <Text fontSize="0.9rem" fontFamily="poppins" mt="0.4rem">
              {/* {director?.basicInfo?.dateOfJoining} */}
              {moment(director?.basicInfo?.dateOfJoining).format('DD-MM-YY') }
     
              </Text>
            </Box>
            <Center h="100px" mx="1rem">
              <Divider
                orientation="vertical"
                borderColor="black"
                borderWidth="0.5px"
              />
            </Center>
  
            <Box>
              <Flex
                fontSize="0.9rem"
                fontFamily="poppins"
                alignItems="center"
                justifyContent={"space-between"}
              >
                <Flex justifyContent="center" alignItems="center">
                  <MdOutlineMailOutline />  {director?.basicInfo?.companyMail}{" "}
                </Flex>{" "}
                <FaRegCopy />
              </Flex>
              <Flex
                fontSize="0.9rem"
                fontFamily="poppins"
                alignItems="center"
                justifyContent={"space-between"}
                mt="0.3rem"
              >
                <Flex justifyContent="center" alignItems="center">
                  {" "}
                  <FiSmartphone />
                  {director?.basicInfo?.phone}{" "}
                </Flex>
                <FaRegCopy />
              </Flex>
              <Flex
                fontSize="0.9rem"
                fontFamily="poppins"
                alignItems="center"
                justifyContent={"space-between"}
                mt="0.3rem"
              >
                <Flex justifyContent="center" alignItems="center">
                  {" "}
                  <MdOutlineMailOutline />{director?.basicInfo?.personalMail}{" "}
                </Flex>{" "}
                <Text
                  color="#333333"
                  fontSize="0.5rem"
                  fontFamily="poppins"
                  ml="0.2rem"
                >
                  {" "}
                  personal
                </Text>
              </Flex>
              <Flex
                fontSize="0.9rem"
                fontFamily="poppins"
                alignItems="center"
                justifyContent={"left"}
                mt="0.3rem"
              >
                <PiCakeFill />  
                <Text>
                {moment(director?.basicInfo?.dob).format('DD-MM-YYYY')}
                </Text>
              </Flex>
            </Box>
            <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
                flexDirection="column" 
                ml={"4rem"}
                bg={"#DCB3F6"}
                padding={"2rem"}
                borderRadius={"1rem"}
                color={"#555555"}
                >
                    <Text >DIN</Text>
                    <Text>
                    {director?.basicInfo?.din}
                    </Text>
                    <MdOutlineContentCopy  
                    />
          </Box>
          </Box>
  
         {/* share count */}
          <Box
            w="30%"
            minW="9rem"
            h="30vh"
            borderRadius="1rem"
            flexDir="column"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bg="#F9F9FB" // Initial background
            position="relative" // Set the position to relative to contain the absolute circle
            _hover={{
              bg: "linear-gradient(90deg, rgba(152, 96, 220, 0.75) 0%, rgba(189, 115, 227, 0.75) 75.4%, rgba(181, 111, 225, 0.75) 100%)", // Background on hover
              "& > .circle": {
                top: "1.3rem", // Move the circle down on hover
                transition: "top 0.2s", // Add animation to the circle
                bg: "#9A61DC",
                color: "black",
              },
              "& > .Leave": {
                fontSize: "1.6rem",
              },
            }}
          >
            <Circle
              className="circle"
              h="1.5rem"
              w="1.5rem"
              position="absolute"
              bg="transparent"
              color="transparent"
              ml="7rem"
              top="-0.1rem" // Initial top position, hidden
            >
              <GoArrowUpRight size={15} />
            </Circle>
            <Text
              fontFamily="poppins"
              fontWeight={600}
              fontSize="1.3rem"
              className="Leave"
            >
              {director?.jobInfo?.shareCount}
            </Text>
            <Text
              fontFamily="poppins"
              fontWeight={500}
              fontSize={{base:"0.9rem",lg:"0.8rem",xl:"0.9rem"}}
              className="Leaves"
            >
             Share Count
            </Text>
          </Box>

        </Box>
       {/* tabs */}
        <Box
          bg="#F9F9FB"
          boxShadow="base"
          w="100%"
          mt="1rem"
          borderRadius="0.5rem"
        >
          <Tabs colorScheme="blue" isLazy bg="white">
            <TabList>
              <Tab fontFamily="poppins" fontWeight={500} fontSize="0.9rem">
                About
              </Tab>
             
              <Tab fontFamily="poppins" fontWeight={500} fontSize="0.9rem">
                Job
              </Tab>
              <Tab fontFamily="poppins" fontWeight={500} fontSize="0.9rem">
                Payroll
              </Tab>
              <Tab fontFamily="poppins" fontWeight={500} fontSize="0.9rem">
                Documents
              </Tab>
            </TabList>
  
            <TabPanels>
              <TabPanel>
                <About data={director?.aboutInfo}/>
              </TabPanel>
             
              <TabPanel> 
                <Job data={{...director?.jobInfo,...{joining:director?.basicInfo?.dateOfJoining,employeId:director?.basicInfo?.employeeId}}}/>
              </TabPanel>
              <TabPanel>
                <Payroll data={{...director?.salaryDetials,...director?.accountDetials}}/>
              </TabPanel>
              <TabPanel>
                <Documents data={director?.accountDetials?.documents}/>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
  
        

<Modal isOpen={modal} onClose={()=>setModal(false)} isCentered={true}>
  <ModalOverlay />
  <ModalContent>
    <ModalHeader></ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      <Formik
      validationSchema={validationSchema} 
        initialValues={{ reason: '' }}
        onSubmit={(values) => {
            axios
              .put(
                `${baseURL}/director/remove?id=${id}`,
                { reason: values.reason }, // Include the reason in the request body
                {
                  headers: { Authorization: "Bearer " + JWTtoken },
                }
              )
              .then((res) => {
                console.log(res.data, 'responseeeeeeeeeeeeee');
                setModal(false);
                // Handle the response as needed
              })
              .catch((err) => {
                isLoading(false);
                if (err.response.data.message) {
                  toast.error(err.response.data.message, {
                    position: "bottom-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                } else if (err.response.data.payment === false) {
                  navigate("/payment");
                }
              });
          }}
      >
         {({values,errors,touched,isSubmitting,formik})=>(
          <Form>
          <Box textAlign="center" pt={1} fontFamily={"poppins"} fontSize={"1rem"} fontWeight={"800"}>
            <Text >Remove Director access</Text>
          </Box>
          <Text pt={"1rem"} fontFamily={"poppins"} fontSize={"0.9rem"} color={"#555555"}>
            Deleting the selected director will permanently revoke all permissions and access. This action is irreversible.
          </Text>
          
          <Field name="reason">
            {({ field }) => (
              <Textarea
                {...field}
                placeholder="Enter reason..."
                mt={3}
              />
            )}
          </Field>
          {/* <ErrorMessage name="reason" component="div" color="red" /> */}
          {errors.reason && touched.reason && (
            <div style={{ color: 'red', fontFamily: 'Poppins', fontSize: '0.6rem' }}>
              {errors.reason}
            </div>
          )}
          
          <ModalFooter>
            <Box display={"flex"} flexDirection={"row"} gap={"1rem"}>
              <Button bg={"#FFFFFF"} onClick={()=>setModal(false)}>No</Button>
              <Button type="submit" color={"#FFFFFF"} bg={"#2A7ECA"} fontFamily={"poppins"} >Yes</Button>
            </Box>
          </ModalFooter>
        </Form>

         )}
        
      </Formik>
    </ModalBody>
  </ModalContent>
</Modal>
      </>
    );
  }
  
  export default DirectorProfile;