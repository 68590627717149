import { configureStore } from '@reduxjs/toolkit' 
import ProfileReducer from './reducers/ProfileSlice' 
import SignupReducer from './reducers/SignUpSlice'
import RedirectReducer from './reducers/RedirectSlice'
import NotificationReducer from './reducers/NotificationSlice'
import UploadReducer from './reducers/UploadSlice'
import CompanyReducer from './reducers/CompanySlice'
import userReducer from './reducers/userReducer'
import orgReducer from './reducers/OrgSlice';
export default configureStore({
    reducer: {
        profile: ProfileReducer,
        signup: SignupReducer,
        company:CompanyReducer,
        redirect: RedirectReducer,
        notification:NotificationReducer,
        upload:UploadReducer,
        user: userReducer,
        org: orgReducer, 
    }
})