import React, { useState } from 'react'
import { Avatar, Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Circle, Divider, Flex, Heading, Image, SimpleGrid, Text, Textarea } from '@chakra-ui/react'
import Feature2 from '../../assets/user (2).webp'
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { MdModeEdit } from 'react-icons/md';

function LeaveCard({data,index,handleApprove,submitting}) {

  const location = useLocation();
  const currentPath = location.pathname;
const [edit, setEdit] = useState(false)


    //bg randomize
  const getRandomColor = () => {
    const colors = ['#E9D4FF','#E8D6CF', '#FFD3EB', '#D2FBD0', '#FFCEAD'];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  const bgColor = getRandomColor();

  const getRandomTextColor = () => {
    const colors = ['#0085FF','#FF9F2D', '#E85AAD', '#E92C2C', '#00BA34'];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  const TextColor = getRandomTextColor();


  const getbgColor = () => {
    const colors = ['#0085FF', '#FF9F2D', '#E85AAD', '#E92C2C', '#00BA34'];
    const randomIndex = Math.floor(Math.random() * colors.length);
    const randomColor = colors[randomIndex];
    // Convert hex color to RGBA
    const hexToRgb = (hex, opacity) => {
        hex = hex.replace('#', '');
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);
        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    };
    return hexToRgb(randomColor, 0.5); // Return color with reduced opacity
};

const bgC = getbgColor();

  return (
    <div>
        
        <Box key="index" boxShadow='0px 3.885714292526245px 5.828571319580078px 0px rgba(0, 0, 0, 0.12)' borderRadius={'9.71px'}>
            <Box 
          bg={bgColor}
          borderRadius={'10px 10px 0px 0px'}  p='0.8rem' >
                              <Flex spacing='4'>
                                <Flex w='100%'  gap='4' alignItems='center' >
                                  <Image name='Segun Adebayo' src={data?.profile}  fallbackSrc={Feature2} borderRadius={'50%'} w='2.5rem' h='2.5rem'/>
                                 <Flex justifyContent={'space-between'} w='75%' columnGap={'0.5rem'}>
                                 <Box>
                                    <Heading fontFamily={'Poppins'} color="rgba(51, 51, 51, 1)" fontWeight={500} fontSize={'0.9rem'}>{data?.firstname} {data?.lastname}</Heading>
                                    <Text fontFamily={'Poppins'} fontWeight={400} color="rgba(51, 51, 51, 0.75)" fontSize={{base:'0.6rem',lg:'0.7rem',xl:'0.7rem'}}>{data?.designation}</Text>
                                  </Box>
                                  <Flex alignItems={'center'}>
                                    <Text fontFamily={'Poppins'} color="rgba(51, 51, 51, 0.75)" fontWeight={500} fontSize={'0.6rem'}>{moment(data?.from).format("DD MMM YYYY")}</Text>
                                  </Flex>
                                 </Flex>
                                 
                                </Flex>
                               
                              </Flex>
                            </Box>
                            <Box p="1rem">
          <Box>
           <Flex flexDir={'row'} w='100%' alignItems={'center'}>
            <Flex flexDir={'column'} gap='0.5rem' textAlign={'center'}>
                <Text fontFamily={'Poppins'} fontWeight={500} fontSize={'0.8rem'}>{moment(data?.from).format('MMM')}</Text>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}  fontFamily={'Poppins'} fontWeight={500} fontSize={'0.8rem'} h="2rem" w='2.5rem' bg="rgba(234, 234, 235, 1)" borderRadius={'7px'}>
                    <Text>{moment(data?.from).format('DD')}</Text>
                </Box>
                <Text fontFamily={'Poppins'} fontWeight={500} fontSize={'0.8rem'} color="rgba(51, 51, 51, 0.5)">{moment(data?.from).format('ddd')}</Text>
            </Flex>
            <Flex flexDir={'column'} gap="0.5rem" flexGrow={1} textAlign={'center'} >
                
                <Flex justifyContent={'center'}>
                <Text color={TextColor} fontSize={'0.9rem'} fontFamily={'Poppins'} fontWeight={500}>{data?.leaveType}</Text>
               {/* <Image src={}/> */}
                </Flex>
                <Divider border="0.73px dashed rgba(125, 125, 125, 1)" />
                <Text fontFamily={'Poppins'} fontWeight={500} fontSize={'0.8rem'} color="rgba(51, 51, 51, 0.5)">{data?.days} Days</Text>
            </Flex>
            <Flex flexDir={'column'} gap='0.5rem' textAlign={'center'}>
                <Text fontFamily={'Poppins'} fontWeight={500} fontSize={'0.8rem'}>{moment(data?.to).format('MMM')}</Text>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}  fontFamily={'Poppins'} fontWeight={500} fontSize={'0.8rem'} h="2rem" w='2.5rem' bg="rgba(234, 234, 235, 1)" borderRadius={'7px'}>
                    <Text>{moment(data?.to).format('DD')}</Text>
                </Box>
                <Text fontFamily={'Poppins'} fontWeight={500} fontSize={'0.8rem'} color="rgba(51, 51, 51, 0.5)">{moment(data?.to).format('ddd')}</Text>
            </Flex>
           </Flex>

           <Textarea bg='rgba(234, 234, 235, 1)' h='5rem' mt="0.5rem" placeholder='---Reason---' color="rgba(51, 51, 51, 0.45)" fontFamily={'Poppins'} fontWeight={500} fontSize={'0.7rem'}
           value={data?.reason}
           />
          

          <Box
          display={data?.leave_status === "approved"?'none':'flex' }
          mt="1rem" borderRadius={'4px'} w="50%" h="2rem"  justifyContent={'center'} alignItems={'center'} bg={data?.leave_status ? bgC:"rgba(229, 243, 255, 1)"}  color={data?.leave_status ?TextColor:"rgba(0, 133, 255, 1)"}>
            <Text fontFamily={'Poppins'} fontWeight={500} fontSize={{base:'0.6rem',lg:'0.7rem',xl:'0.7rem'}}>
            {data?.remaining} Leaves Availiable

            </Text>
            </Box>
          </Box>

{
  currentPath.includes('/leave-history')?
  data?.leave_status ==="rejected" ?
 <Box w="100%" mt="0.5rem">

<Button
_hover={{bg:'rgba(190, 24, 2, 1)'}}
w="100%"
bg="rgba(190, 24, 2, 1)" color="rgba(255, 255, 255, 1)" fontWeight={500} fontFamily={"Poppins"}>
    Rejected
  </Button>
 </Box>


  
  :
  <Box  mt="0.5rem">

    {
      edit && data?.leave_status==='approved'?

      <Box display='flex' justifyContent={'space-between'} mt='1rem' gap="1rem" w="100%">
          <Button    
            isDisabled={submitting}

          onClick={() => handleApprove("rejected", data?._id)} _hover={{bg:'rgba(190, 24, 2, 1)'}} w="50%"  bg="rgba(190, 24, 2, 1)" borderRadius={'5px'} color="rgba(255, 255, 255, 1)" >
              Reject
            </Button>
            <Button 
            isDisabled={submitting}
            onClick={() => {handleApprove("approved", data?._id)
            setEdit(false)
            }} _hover={{bg:'rgba(69, 119, 69, 1)'}} w="50%" bg="rgba(69, 119, 69, 1)" borderRadius={'5px'} color="rgba(255, 255, 255, 1)" >
            Approve
            </Button>
          </Box>
      :
    <>
    <Button  mt="0.3rem" w="100%"
    _hover={{bg:'rgba(69, 119, 69, 1)'}}
    bg="rgba(69, 119, 69, 1)" color="rgba(255, 255, 255, 1)">Approved</Button>
    <Box
    cursor="pointer"
    onClick={()=>{setEdit(true)}}
    mt="0.3rem" display="flex" w="100%" justifyContent={"center"} alignItems={"center"} gap="0.5rem">
      <Text 
      fontFamily={"Poppins"}
      fontWeight={500}
      color="rgba(85, 85, 85, 1)"
       fontSize={"0.8rem"}>Edit</Text>
    <Circle size="20px" bg="rgba(217, 217, 217, 1)">
      <MdModeEdit />
      </Circle>
    </Box>
    </>
}
  </Box>
    

  :


          <Box display='flex' justifyContent={'space-between'} mt='1rem' gap="1rem" w="100%">
          <Button    
            isDisabled={submitting}

          onClick={() => handleApprove("rejected", data?._id)} _hover={{bg:'rgba(190, 24, 2, 1)'}} w="50%"  bg="rgba(190, 24, 2, 1)" borderRadius={'5px'} color="rgba(255, 255, 255, 1)" >
              Reject
            </Button>
            <Button 
            isDisabled={submitting}
            onClick={() => handleApprove("approved", data?._id)} _hover={{bg:'rgba(69, 119, 69, 1)'}} w="50%" bg="rgba(69, 119, 69, 1)" borderRadius={'5px'} color="rgba(255, 255, 255, 1)" >
            Approve
            </Button>
          </Box>
}
          </Box>

            </Box> 
    </div>
  )
}

export default LeaveCard