import React, { useState } from "react";
import {
  Icon,
  Box,
  Button,
  SimpleGrid,
  Text,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";
import { BiSolidFilePdf } from "react-icons/bi";
import { BiExpand } from "react-icons/bi";
import PDFViewerComponent from "./PDFViewerComponent";
import { BsArrowLeft, BsDownload } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { TbReplace } from "react-icons/tb";
import { html2pdf } from "html2pdf.js";
import PDFViewer from "../Testing";

function Documents({ data }) {
 
 //modal details display
 const [singleDoc, setsingleDoc] = useState([])

 const handleDoc=(id)=>{
  console.log("singleDoc"+id)
const singleDetails=data?.filter(doc=>doc.id == id)
setsingleDoc(singleDetails)

  }
  
  const expandModalDisclosure = useDisclosure();
  const detailsModalDisclosure = useDisclosure();

  const isHover = expandModalDisclosure.isOpen || detailsModalDisclosure.isOpen;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const hoverBgColor = useColorModeValue("gray.100", "gray.700"); // Adjust colors for light/dark mode

  const containerStyle = {
    position: "relative",
    backgroundColor: "#F9F9FB",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    pt: "0.5rem",
    pb: "1rem",
    borderRadius: "10px",
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
  };

  const buttonStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    filter:"none"
  };

  //download function
  //download function for estimate pdf goes here
  const handleDownloadPDF = (id) => {
    const content = document.getElementById(id);

    if (content) {
      const pdfOptions = {
        margin: 10,
        filename: "Download.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      html2pdf().from(content).set(pdfOptions).save();
    }
  };

  return (
    <SimpleGrid columns={[2, 3, 4, 5]} gap="2rem 8vw">
       {data?.map((data,index)=>{
          return(
            <Box {...containerStyle} key={index}>
       
        <Box display="grid" pb="0.5rem" width="100%" pr="0.5rem">
          <Icon
            as={BiExpand}
            justifySelf="end"
            fontSize="1.2rem"
            onClick={() => {
              handleDoc(data.id);

              expandModalDisclosure.onOpen();
              
            }}
            />
          <Icon
            as={BiSolidFilePdf}
            justifySelf="center"
            fontSize="3rem"
            color="red"
          />
        </Box>
        <Text fontFamily={"poppins"} fontWeight={500}  fontSize={{
          base: "0.8rem",
          md: "0.7rem",
          lg: "0.8rem",
          xl: "0.8rem",
          "2xl": "0.9rem",
        }}>
         {data.name}
        </Text>
        {isHovered && (
          <Button
          fontSize={{
            base: "0.8rem",
            md: "0.7rem",
            lg: "0.8rem",
            xl: "0.8rem",
            "2xl": "0.9rem",
          }}
          h="2rem"
            style={buttonStyle}

            onClick={() => {
              handleDoc(data.id);

              detailsModalDisclosure.onOpen();
              
            }}
            zIndex={1} // Ensure the button stays above the blurred container
          >
            See Details
          </Button>
        )}
      </Box>
          )
        })}
      
      {/* Modal */}
      <Modal
        isOpen={expandModalDisclosure.isOpen}
        onClose={expandModalDisclosure.onClose}
        size="xl"
        backgroundColor="transparent"
        opacity="0.1"
      >
        <ModalOverlay />

        {singleDoc.map((data,index)=>{
          return(
            <ModalContent key={"index"}>
            <ModalHeader position={"absolute"} top="-3rem" width="100%">
              <Flex direction="row" justifyContent={"space-between"} width="100%">
                <Box display={"flex"} flexDirection={"row"}>
                  <BsArrowLeft onClick={expandModalDisclosure.onClose} cursor={"pointer"}/>
                  <Text fontSize={"1rem"} ml="1rem">
                   {data?.name ?? "Accounts"}
                  </Text>
                </Box>
                <Box display={"flex"} flexDirection={"row"} gap="1rem">
                  <BsDownload
                    onClick={() => handleDownloadPDF("pdf-file-expand")}
                    cursor={"pointer"}
                  />
                  <MdDelete  cursor={"pointer"}/>
                  <TbReplace  cursor={"pointer"}/>
                </Box>
              </Flex>
            </ModalHeader>
            <ModalBody>
              <div id="pdf-file-expand">
                {" "}
                {/* Changed the id here */}
                <PDFViewer url={data?.url} />
              </div>
            </ModalBody>
          </ModalContent>
          )
        })}
       
      </Modal>

      <Modal
        isOpen={detailsModalDisclosure.isOpen}
        onClose={detailsModalDisclosure.onClose}
        size="xs"
      >
        <ModalOverlay />
        {singleDoc.map((data)=>{
  return(
        <ModalContent
          color={"white"}
          backgroundColor={"rgba(0,0,0,0.6)"}
          position="fixed"
          top={"50%"}
          right={0}
          transform="translate(50%, 50%)"
        >
          <Flex>
            </Flex>  
          <ModalHeader fontSize={"1rem"}>File Preview</ModalHeader>
          <ModalCloseButton mt="0.5rem" fontSize={"0.5rem"} w="1.5rem" h="1.5rem" color="black" bg="white" border={"0.1rem solid "} borderRadius={"50%"}/>

    <ModalBody>
    <div id="pdf-file-details">
      {" "}
      {/* Changed the id here */}
      <PDFViewer url="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf" />
    </div>
    <Flex justifyContent={"center"} gap="1rem" mb="1rem">
        <Flex direction={"column"} alignItems={"center"}>
        <Button
        width={"20px"}
        borderRadius={"10px"}
        backgroundColor={"#A7A7A7"}
        onClick={() => handleDownloadPDF("pdf-file-details")}
      >
        <Icon as={BsDownload} fontSize={"1rem"} color="#333333"/>
        <BsDownload color="#333333" />
      </Button>
      <Text fontFamily={"poppins"} fontSize={"0.6rem"}>Download</Text>
        </Flex>
     
        <Flex direction={"column"} alignItems={"center"}>
        <Button
             width={"20px"}
            borderRadius={"10px"} backgroundColor={"#A7A7A7"}>
        {" "}
        <Icon as={MdDelete} fontSize={"1rem"} color="#333333"/>

      </Button>
      <Text fontFamily={"poppins"} fontSize={"0.6rem"}>Delete</Text>
        </Flex>
     
        <Flex direction={"column"} alignItems={"center"}>
        <Button 
                        width={"20px"}

        borderRadius={"10px"} backgroundColor={"#A7A7A7"}>
        {" "}
        <Icon as={TbReplace} fontSize={"1rem"} color="#333333"/>

      </Button>
      <Text fontFamily={"poppins"} fontSize={"0.6rem"}>Replace</Text>
        </Flex>
     
     
    </Flex>
    <hr />
    <Box display={"grid"} mt="0.5rem">
      <Text fontFamily={"poppins"} justifySelf={"center"} fontSize={"0.8rem"}>{data.name}</Text>
      <Flex justifyContent={"space-between"} >
        <Box fontSize={"0.7rem"} fontWeight={200}>
          <Text fontFamily={"poppins"} >Size</Text>
          <Text fontFamily={"poppins"}>Created Date</Text>
          <Text fontFamily={"poppins"}>Date updated</Text>
        </Box>
        <Box fontSize={"0.7rem"}>
          <Text>-</Text>
          <Text>-</Text>
          <Text>-</Text>
        </Box>
        <Box fontSize={"0.7rem"}>
          <Text fontFamily={"poppins"}>500kb</Text>
          <Text fontFamily={"poppins"}>04/08/2023</Text>
          <Text fontFamily={"poppins"}>08/08/2023</Text>
        </Box>
      </Flex>
    </Box>
  </ModalBody>
      
        </ModalContent>
         )

        })}
            
      </Modal>
    </SimpleGrid>
  );
}

export default Documents;
