import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CSSReset, ChakraProvider } from "@chakra-ui/react";
import asyncComponent from "./AsyncComponent";
import { useDispatch, useSelector } from "react-redux";
import socketIOClient from "socket.io-client";
import axios from "axios";
import { useEffect } from "react";
import React, { lazy, Suspense } from 'react';
import { updateNot } from "./reducers/NotificationSlice";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { BsExclamationCircle } from "react-icons/bs";
import PDFViewer from "./Components/Testing";
import Calender from "./Components/Team connect/Calender";
import ScheduleTask from "./Components/TaskFlow/ScheduleTask";
import DirectorProfile from "./Components/Director/DirectorProfile";
import LeaveHistory from "./Components/Team connect/LeaveHistory";
const InvoiceEdit = asyncComponent(() => import("./Components/Transaction/InvoiceEdit"));
const Estimate = asyncComponent(() => import("./Components/Transaction/Estimate"));
const CreditNote = asyncComponent(() => import("./Components/Transaction/CreditNote"));
const CreditReceipt = asyncComponent(() => import("./Components/Transaction/CreditReceipt"));
const Ledger = asyncComponent(() => import("./Components/Transaction/Ledger"));
const TrailBalance = asyncComponent(() => import("./Components/Transaction/TrailBalance"));
const BalanceSheet = asyncComponent(() => import("./Components/Transaction/BalanceSheet"));
const Cashflow = asyncComponent(() => import("./Components/Transaction/Cashflow"));
const Receivables = asyncComponent(() => import("./Components/Transaction/Receivables"));
const Payables = asyncComponent(() => import("./Components/Transaction/Payables"));
const ProfitAndLose = asyncComponent(() => import("./Components/Transaction/ProfitAndLose"));
const Bank = asyncComponent(() => import("./Components/Accounting/Bank"));
const DaybookModal = asyncComponent(() => import("./Components/Transaction/DaybookModal"));
const EmployeeProfile = asyncComponent(() => import("./Components/Hr/EmployeeProfile"));
const Landing = asyncComponent(() => import('./Components/Landing/Landing'));
const VotingMain = asyncComponent(() => import("./Components/Voting/VotingMain"));
const TermsAndConditions = asyncComponent(() => import("./Components/Landing/TermsAndConditions"));
const Privacy = asyncComponent(() => import("./Components/Landing/Privacy"));
const FaqPage = asyncComponent(() => import("./Components/Landing/FaqPage"));
const About = asyncComponent(() => import("./Components/Landing/About"));
const Journal = asyncComponent(() => import("./Components/Transaction/Journal"));
const Daybook = asyncComponent(() => import("./Components/Transaction/Daybook"));
const Setup = asyncComponent(() => import("./Components/Team connect/Setup"));
const Main = asyncComponent(() => import("./Components/Team connect/Main"));
const AddEmployee = asyncComponent(() => import("./Components/Team connect/AddEmployee"));
const Attendance = asyncComponent(() => import("./Components/Team connect/Attendance"));
const EmployeeAttendence = asyncComponent(() => import("./Components/Team connect/EmployeeAttendence"));

const Connect = asyncComponent(() => import("./Components/Team connect/Connect"));
const Leave = asyncComponent(() => import("./Components/Team connect/Leave"));
const ViewAllRequest = asyncComponent(() => import("./Components/Team connect/ViewAllRequest"));
const AllOnleave = asyncComponent(() => import("./Components/Team connect/AllOnleave"));
const SingleLeaveDetails = asyncComponent(() => import("./Components/Team connect/SingleLeaveDetails"));
const EditCalender = asyncComponent(() => import("./Components/Team connect/EditCalender"));
const HolidayCalender = asyncComponent(() => import("./Components/Team connect/HolidayCalender"));
const TestingOne = asyncComponent(() => import("./Components/TestingOne"));
const Feed = asyncComponent(() => import("./Components/Team connect/Feed"));
const Payroll = asyncComponent(() => import("./Components/Team connect/Payroll"));
const PaymentHistory = asyncComponent(() => import("./Components/Team connect/PaymentHistory"));
const RunPayroll = asyncComponent(() => import("./Components/Team connect/RunPayroll"));
const PayrollSingle = asyncComponent(() => import("./Components/Team connect/PayrollSingle"));
const Settings = asyncComponent(() => import("./Components/Team connect/Settings"));
const Onboarding = asyncComponent(() => import("./Components/Team connect/OnBoading"));
const Offboarding = asyncComponent(() => import("./Components/Team connect/Offboarding"));
const Director = asyncComponent(() => import("./Components/Director/Director"));
const AddDirector = asyncComponent(() => import("./Components/Director/AddDirector"));
const TaskFlow = asyncComponent(() => import("./Components/TaskFlow/Main"));
const Newmain = asyncComponent(() => import("./Components/TaskFlow/Newmain"));
const AddInvestor = asyncComponent(() => import("./Components/Investor/AddInvestor"));
const Taskflow = asyncComponent(() => import("./Components/TaskFlow/Taskflow"));
const InvestorShow = asyncComponent(() => import("./Components/Investor/InvestorShow"));
const SingleTask = asyncComponent(() => import("./Components/TaskFlow/Tasks"));
const AddTask = asyncComponent(() => import("./Components/TaskFlow/AddTask"));
const TrackTime = asyncComponent(() => import("./Components/TaskFlow/TrackEvent"));
const Start = asyncComponent(() => import("./Components/Setup"));
const Login = asyncComponent(() => import("./Components/Login/Login"));
const Register = asyncComponent(() => import("./Components/Login/Register"));
const Layout = asyncComponent(() => import("./Components/Layout/Layout"));
const Dashboard = asyncComponent(() => import("./Components/Layout/Dashboard"));
const NotesMain = asyncComponent(() => import("./Components/Notes/NotesMain"));
const DocsMain = asyncComponent(() => import("./Components/Docs/DocsMain"));
const Forgot = asyncComponent(() => import("./Components/Login/Forgot"));
const Reset = asyncComponent(() => import("./Components/Login/Reset"));
const MainDocsEmpty = asyncComponent(() =>
  import("./Components/Docs/mainDocs")
);
const MainFiles = asyncComponent(() => import("./Components/Docs/Filesmain"));
const DocsFile = asyncComponent(() => import("./Components/Docs/Files"));
const Faq = asyncComponent(() => import("./Components/Sidebar/Faq"));
const StoragePage = asyncComponent(() =>
  import("./Components/Docs/StoragePage")
);
const CompanyPage = asyncComponent(() =>
  import("./Components/Company/CompanyPage")
);
const Profile = asyncComponent(() => import("./Components/Profile/ProfileOne"));
const NewProfile = asyncComponent(() =>
  import("./Components/Profile/NewProfile")
);
const Todo = asyncComponent(() => import("./Components/Todo/Todo"));

const AccountMain = asyncComponent(() =>
  import("./Components/Transaction/AccountMain")
);
const Inventory = asyncComponent(() =>
  import("./Components/Transaction/Inventory")
);
const Customers = asyncComponent(() =>
  import("./Components/Transaction/Customers")
);
const Invoice = asyncComponent(() =>
  import("./Components/Transaction/Invoice")
);
const Investors = asyncComponent(() =>
  import("./Components/Investor/Investor")
);
const InvestorDetails = asyncComponent(() =>
  import("./Components/Investor/InvestorDetails")
);
const Employees = asyncComponent(() =>
  import("./Components/Team connect/Employees")
);
const NewEmployee = asyncComponent(() =>
  import("./Components/Employee/NewEmployee")
);
const NotificationList = asyncComponent(() =>
  import("./Components/Notification/Notification")
);
const EmployeeDetails = asyncComponent(() =>
  import("./Components/Employee/EmployeeDetails")
);

const DirectorDetails = asyncComponent(() =>
  import("./Components/Director/DirectorDetails")
);

const SetPassword = asyncComponent(() =>
  import("./Components/Investor/SetPassword")
);
const SetDirectorPassword = asyncComponent(() =>
  import("./Components/Director/SetDirectorPassword")
);
const Feedback = asyncComponent(() => import("./Components/Sidebar/Feedback"));
const Perks = asyncComponent(() => import("./Components/Sidebar/Perks"));
const AddOn = asyncComponent(() => import("./Components/Sidebar/AddOn"));
const Support = asyncComponent(() => import("./Components/Sidebar/Support"));
const Report = asyncComponent(() => import("./Components/Sidebar/Report"));
const Cancel = asyncComponent(() => import("./Components/Payment/Cancel"));
const PaymentMain = asyncComponent(() =>
  import("./Components/Payment/PaymentMain")
);
const InvoiceShow = asyncComponent(() =>
  import("./Components/Transaction/InvoiceShow")
);
const notificationSound = asyncComponent(() =>
  import("../src/assets/Notification.mp3")
);

function App() {
  const user = JSON.parse(localStorage.getItem("user"));
  const baseURL = process.env.REACT_APP_URL;
  const notificationTitle = useSelector((state) => state.notification.title);
  async function getSubscriptions() {
    try {
      let subscription = await axios.get(
        "baseURL/profile/subscription".replace("baseURL", baseURL),
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("user"))?.token,
          },
        }
      );
      subscription = subscription.data?.data;
      if (subscription?.includes(process.env.REACT_APP_STRIPE_FREE)) {
        localStorage.setItem("subscription", process.env.REACT_APP_STRIPE_FREE);
      } else {
        localStorage.setItem("subscription", subscription);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  }

  const dispatch = useDispatch();
 
  useEffect(() => {
    if (user) {
      getSubscriptions();
      // Connect to the Socket.IO server

      const authObject = {
        token: user.token,
      };

      const socket = socketIOClient(process.env.REACT_APP_URL, {
        auth: authObject,
        
      });

      console.log(user.registerid, "uerIddd");

      // Emit an 'addUser' event with the user ID
      socket.emit("addUser", user.registerid);

      // Listen for 'notification' event
      socket.on("notification", (notification) => {
        dispatch(updateNot());
        console.log(
          notification,
          "Socket is working and received a notification"
        );
        toast.error(notification.message, {
          position: "bottom-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });  
      });

      socket.on("error", (error) => {
        console.log(
          "Errorrrrrrrrrrrr",error
        );
     
  
      });

      // Clean up the socket connection on component unmount
      return () => {
        socket.disconnect();
      };
    }
  }, [user]);

  return (
    <ChakraProvider>
      <ToastContainer/>
      <CSSReset />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<Forgot />} />
          <Route path="/reset/:id/:token/:expires" element={<Reset />} />
          <Route path="/set/:id/:token/:expires" element={<SetPassword />} />
          <Route path="/setDirector/:id/:expires" element={<SetDirectorPassword />} />
          <Route path="/landing" element={<Landing/>}/>
           <Route path="/terms" element={<TermsAndConditions/>} />
           <Route path="/privacy" element={<Privacy/>} />
           <Route path="/faqpage" element={<FaqPage/>} />
           <Route path="/aboutus" element={<About/>} />


          {user && (
            <Route path="/" element={<Layout />}>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="/notes" element={<NotesMain />} />
              <Route path="/feedback" element={<Feedback />} />
              <Route path="/modal" element={<DaybookModal />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/perks" element={<Perks />} />
              <Route path="/add-on" element={<AddOn />} />
              <Route path="/support" element={<Support />} />
              <Route path="/report" element={<Report />} />
              <Route path="/cancel" element={<Cancel />} />
              <Route path="/payment" element={<PaymentMain />} />
              <Route path="/documents/view/:id" element={<DocsFile />} />
              <Route path="/documents/main/:id" element={<MainFiles />} />
              <Route path="/documents" element={<DocsMain />} />
              <Route path="/main-docs" element={<MainDocsEmpty />} />
              <Route path="/storage" element={<StoragePage />} />
              <Route path="/companypage" element={<CompanyPage />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/profile/edit" element={<NewProfile />} />
              <Route path="/profile/add-profile" element={<NewProfile />} />
              <Route path="/todo" element={<Todo />} />
              <Route path="/voting" element={<VotingMain />} />
              <Route path="/inventory" element={<Inventory />} />
              <Route path="/sales" element={<Invoice />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/supplier" element={<Customers />} />
              <Route path="/transactions" element={<AccountMain />} />
              <Route path="/accounts" element={<Bank/>} />
              <Route path="/creditreceipt/:id" element={<CreditReceipt />} />
              <Route path="/investors" element={<Investors />} />
              <Route path="/investor/:id" element={<InvestorShow />} />
              <Route 
                path="/investors/investor-details"
                element={<InvestorDetails />}
              />
             <Route path="/start" element={<Start />} />  
               {/* <Route path="/employees/new-employee" element={<NewEmployee />} />
              <Route path="/employees/edit/:id" element={<NewEmployee />} /> */}
              <Route path="/notifications" element={<NotificationList />} />
              <Route
                path="/employees/employee-details"
                element={<EmployeeDetails />}
              />
              <Route path="/directors" element={<Director/>} />
              <Route path="/adddirector" element={<AddDirector />} />
             
              <Route path="/editdir/:id" element={<AddDirector/>} />

              <Route path="/directors/:id" element={<Director/>} />
              <Route path="/director/:id" element={<DirectorProfile />} />

              <Route
                path="/directors/director-details/:id"
                element={<DirectorDetails />}
              />
              <Route path="/invoiceadd" element={<InvoiceEdit />} />
              <Route path="/invoiceedit/:id" element={<InvoiceEdit />} />
              <Route path="/invoiceshow/:id" element={<InvoiceShow />} />
              <Route path="/testing" element={<TestingOne />} />
              <Route path="/estimate" element={<Invoice />} />
              <Route path="/receipt" element={<Invoice />} />
              <Route path="/estimateadd" element={<Estimate />} />
              <Route path="/creditnoteadd" element={<CreditNote />} />
              <Route path="/creditnoteedit/:id" element={<CreditNote />} />
              <Route path="/debitnoteadd" element={<CreditNote />} />
              <Route path="/debitnoteedit/:id" element={<CreditNote />} />
              <Route path="/creditnote" element={<Invoice />} />
              <Route path="/debitnote" element={<Invoice />} />
              <Route path="/receiptadd" element={<InvoiceEdit />} />
              <Route path="/receiptedit/:id" element={<InvoiceEdit />} />
              <Route path="/estimateedit/:id" element={<Estimate />} />
              <Route path="/purchaseinvoice" element={<Invoice />} />
              <Route path="/lnvoiceedit/:id" element={<InvoiceEdit />} />
              <Route path="/expense" element={<Invoice />} />
              <Route path="/expenseadd" element={<InvoiceEdit />} />
              <Route path="/expenseedit/:id" element={<InvoiceEdit />} />
              <Route path="/lnvoiceadd" element={<InvoiceEdit />} />
              <Route path="/journal" element={<Journal />} />
              <Route path="/ledger" element={<Ledger />} />
              <Route path="/ledgerview/:name" element={<Ledger />} />
              <Route path="/calender" element={<Calender />} />

              <Route path="/trailbalace" element={<TrailBalance />} />
              <Route path="/pl" element={<ProfitAndLose />} />
              <Route path="/balance" element={<BalanceSheet />} />
              <Route path="/cashflow" element={<Cashflow />} />
              <Route path="/receivable" element={<Receivables />} />
              <Route path="/payables" element={<Payables />} />
              <Route path="/employeeProfile/:id" element={<EmployeeProfile />} />
              <Route path="/daybook" element={<Daybook />} />



              <Route path="/addemp" element={<AddEmployee/>} />
              <Route path="/editemp/:id" element={<AddEmployee/>} />
              <Route path="/setup" element={<Setup/>} />
              <Route path="/employees" element={<Main/>} />
              <Route path="/emp-attendance/:id" element={<EmployeeAttendence />} />

              <Route path="/attendence" element={<Attendance/>} />
              <Route path="/totalemp" element={<Employees/>} />
              <Route path="/connect" element={<Connect/>} />
              <Route path="/leave" element={<Leave/>} />
              <Route path="/leaverequest" element={<ViewAllRequest/>} />
              <Route path="/onleave" element={<AllOnleave/>} />
              <Route path="/editcalender" element={<EditCalender />} />
              <Route path="/holidaycalender" element={<HolidayCalender />} />
              <Route path="/feed" element={<Feed/>} />
              <Route path="/payroll" element={<Payroll/>} />
              <Route path="/paymenthistory" element={<PaymentHistory/>} />
              <Route path="/runpayroll" element={<RunPayroll/>} />
              <Route path="/singlePayroll/:id" element={<PayrollSingle/>} />
              <Route path="/teamconnect-settings" element={<Settings/>} />
              <Route path="/onboarding" element={<Onboarding/>} />
              <Route path="/offboarding/:id" element={<Offboarding/>} />
              <Route path="/singleleave/:id" element={<SingleLeaveDetails/>} />
              <Route path="/leave-history" element={<LeaveHistory/>} />

              
              <Route path="/track" element={<TrackTime/>}/>
               

              <Route path="/taskflow" element={<Taskflow/>} />


              <Route path="/singletask/:id" element={<SingleTask/>} />
              <Route path="/addtask" element={<AddTask/>} />
              <Route path="/addtask/:id" element={<AddTask/>} />
              <Route path="/managetask" element={<Newmain/>} />
              <Route path="/schedule" element={<ScheduleTask/>} />

              
              <Route path="/addinvestor" element={<AddInvestor/>} />
              <Route path="/editinvestor/:investorId" element={<AddInvestor/>} />




            </Route>
          )}
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
