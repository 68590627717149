import { createSlice } from '@reduxjs/toolkit';

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    change: false,
  },
  reducers: {
    updateNot: (state) => {
      state.change = !state.change;
    },
  },
});

export const { updateNot } = notificationSlice.actions;
export default notificationSlice.reducer;
