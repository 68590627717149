import { Box, Circle, Flex, Image, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import Background from "../../assets/pngwing 1.png";
import Logo from "../../assets/image 16.png";
import Background2 from "../../assets/pngwing2.png";
import { BsAsterisk } from "react-icons/bs";

function Payroll({data}) {
  console.log(data,'DIRECTOR PAY-ROLL--------------------')
  return (
    <SimpleGrid
      columns={[1, 1, 1, 2, 4, 4]}
      justifyContent={"space-between"}
      justifyItems={"center"}
    >
      <Box
        position="relative"
        bg="#C490E5"
        height="15rem"
        borderRadius="1.5rem"
        w="20vw"
        minW="18rem"
      >
        <Flex justifyContent="space-between">
          <Box h="1rem" w="3rem" />
          <Box bg="white" h="1rem" w="5rem" borderBottomRadius="0.8rem"></Box>

          <Box
            w="3.5rem"
            height="15rem"
            backgroundImage={Background}
            borderRightRadius="1.5rem"
            overflow="hidden"
            display="flex"
            alignSelf="right"
          >
            <Text
              fontFamily={"Azonix"}
              transform="rotateX(90deg)"
              textAlign="center"
              fontSize="1.8rem"
            >
              AXIS
            </Text>
          </Box>
        </Flex>
        <Box
          width="100%"
          position="absolute"
          top="30%"
          left="50%"
          transform={"translate(-50%,-30%)"}
        >
          <Flex justify={"space-evenly"} mt="1rem">
            <Image src={Logo} h="2rem" w="auto" />
            <Flex position="relative" ml="4rem" mr="4rem">
              <Box
                zIndex="3"
                width="1rem"
                height="1rem"
                borderRadius="50%"
                bg="#892DC2"
                className="circle-animation-1"
              ></Box>
              <Box
                zIndex="2"
                position="absolute"
                left="0.5rem"
                width="1rem"
                height="1rem"
                borderRadius="50%"
                bg="#A24FD6"
                className="circle-animation-2"
              ></Box>
              <Box
                zIndex="1"
                position="absolute"
                left="1rem"
                width="1rem"
                height="1rem"
                borderRadius="50%"
                bg="#4C196C"
                className="circle-animation-3"
              ></Box>
            </Flex>
          </Flex>
          <Flex justifyContent={"space-between"} ml="2rem" mr="4rem" mt="1rem">
            <Box
              fontSize={{ xl: "0.7rem", "2xl": "0.9rem" }}
              fontWeight={500}
              lineHeight={"1.8rem"}
            >
              <Text fontFamily={"poppins"}>A/c Number</Text>
              <Text fontFamily={"poppins"}>A/c Name</Text>
              <Text fontFamily={"poppins"}>Bank Name</Text>
              <Text fontFamily={"poppins"}>IFSC Code</Text>
              <Box
                mt="0.5vw"
                minWidth="5vw"
                backgroundColor={"white"}
                height="3px"
              ></Box>
            </Box>
            <Box fontSize={"0.7rem"} lineHeight={"1.8rem"}>
              <Text>-</Text>
              <Text>-</Text>
              <Text>-</Text>
              <Text>-</Text>
            </Box>
            <Box fontSize={"0.9rem"} fontWeight={700} lineHeight={"1.8rem"}>
              <Text fontFamily={"poppins"}>{data.accountNumber}</Text>
              <Text fontFamily={"poppins"}>{data.accountName}</Text>
              <Text fontFamily={"poppins"}>{data.bankName}</Text>
              <Text fontFamily={"poppins"}>{data.IFSC_code}</Text>
              <Box
                mt="0.5vw"
                minWidth="5vw"
                backgroundColor={"white"}
                height="3px"
              ></Box>
            </Box>
          </Flex>
        </Box>
      </Box>

      <Box>
        <Field title="Account Type" value={data.accountType}/>
        <Field title="Bank Branch" value={data.bankBranch} />
        <Field title="PAN Number" value={data.panNumber} />
      </Box>

      <Box>
        <Field title="Salary" value={data.salary} />
        <Field title="Salary Date" value={data.salaryDate ?? '-'} />
        <Field title="Salary Period" value={data.salaryPeriod} />
      </Box>

      <Box
        position="relative"
        bg="#C490E5"
        height="15rem"
        borderRadius="1.5rem"
        width="17vw"
        minW="15rem"
      >
        <Box
          boxShadow="0px 4px 5px 0px #00000026"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          h="3.1rem"
          overflow="hidden"
          fontSize={"1.8rem"}
          backgroundImage={Background2}
          borderTopRadius="1.5rem"
        >
          <Text fontFamily={"poppins"}>SALARY</Text>
        </Box>

        <Box
          width={"100%"}
          position="absolute"
          top="50%"
          left="50%"
          transform={"translate(-50%,-50%)"}
        >
          <Flex justify="space-evenly" alignItems={"center"} mt="2rem">
            <Box fontSize={"0.9rem"} fontWeight={500} lineHeight={"2rem"}>
              <Flex
                justify="flex-start"
                align={"center"}
                color="#333333"
                opacity={"0.75"}
              >
                <BsAsterisk fill="#333333" fontSize={"0.6rem"} />
                <Text fontFamily={"poppins"} ml="0.2rem">
                  Basic
                </Text>
              </Flex>
              <Flex
                justify="flex-start"
                align={"center"}
                color="#333333"
                opacity={"0.75"}
              >
                <BsAsterisk fill="#333333" fontSize={"0.6rem"} />
                <Text fontFamily={"poppins"} ml="0.2rem">
                  HRA
                </Text>
              </Flex>
              <Flex
                justify="flex-start"
                align={"center"}
                color="#333333"
                opacity={"0.75"}
              >
                <BsAsterisk fill="#333333" fontSize={"0.6rem"} />
                <Text fontFamily={"poppins"} ml="0.2rem">
                  TA
                </Text>
              </Flex>{" "}
              <Flex
                justify="flex-start"
                align={"center"}
                color="#333333"
                opacity={"0.75"}
              >
                <BsAsterisk fill="#333333" fontSize={"0.6rem"} />
                <Text fontFamily={"poppins"} ml="0.2rem">
                  DA
                </Text>
              </Flex>
            </Box>
            <Box fontSize={"0.9rem"} lineHeight={"2rem"}>
              <Text>-</Text>
              <Text>-</Text>
              <Text>-</Text>
              <Text>-</Text>
            </Box>
            <Box fontSize={"0.9rem"} fontWeight={700} lineHeight={"2rem"}>
              <Text fontFamily={"poppins"}></Text>
              <Text fontFamily={"poppins"}></Text>
              <Text fontFamily={"poppins"}></Text>
              <Text fontFamily={"poppins"}></Text>
            </Box>
          </Flex>
        </Box>
      </Box>
    </SimpleGrid>
  );
}

const Field = (props) => {
  const { title, value } = props;
  return (
    <Box mt="1rem">
      <Text
        color="#333333"
        fontFamily="poppins"
        fontSize={{
          base: "0.8rem",
          md: "0.7rem",
          lg: "0.8rem",
          xl: "0.8rem",
          "2xl": "0.9rem",
        }}
        opacity="60%"
      >
        {title}
      </Text>
      <Text
        w="12rem"
        bg="#F9F9FB"
        textAlign="center"
        py="0.6rem"
        px="0.5rem"
        borderRadius="0.7rem"
        color="#333333"
        fontFamily="poppins"
        fontSize={{
          base: "0.8rem",
          md: "0.7rem",
          lg: "0.8rem",
          xl: "0.8rem",
          "2xl": "0.9rem",
        }}
        fontWeight={500}
      >
        {value}
      </Text>
    </Box>
  );
};

export default Payroll;
