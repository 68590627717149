import { createSlice } from '@reduxjs/toolkit' //next js redux toolkit  
const user = JSON.parse(localStorage.getItem('user'))
export const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        value: 0,
        signedUrl:user?.signedUrl,
        image:user?.image,
        email:user?.email,
        name:user?.firstname,
        phone:user?.phone,
        lastname:user?.lastname,
        dob:user?.dob,
        father:user?.fathersname,
        address:user?.address,
        updated: false
    },
    reducers: {
        increase: state => {
            state.value += 1
        },
        decrease: state => {
            state.value -= 1
        },
        update: state => {
            state.signedUrl = JSON.parse(localStorage.getItem('user')).signedUrl;
            state.email = JSON.parse(localStorage.getItem('user')).email;
            state.image = JSON.parse(localStorage.getItem('user')).image;
            state.name = JSON.parse(localStorage.getItem('user')).firstname;
            state.phone = JSON.parse(localStorage.getItem('user')).phone;
            state.dob = JSON.parse(localStorage.getItem('user')).dob;
            state.lastname = JSON.parse(localStorage.getItem('user')).lastname;
            state.father = JSON.parse(localStorage.getItem('user')).fathersname;
            state.address = JSON.parse(localStorage.getItem('user')).address;
        },
        profileUpdate: state => {
            state.updated = !state.updated
        }
    }
})
// case under reducers becomes an action 
export const { increase, decrease, update,profileUpdate } = profileSlice.actions
export default profileSlice.reducer 