import { createSlice } from '@reduxjs/toolkit' //next js redux toolkit  
const user = JSON.parse(localStorage.getItem('user'))
export const companySlice = createSlice({
    name: 'company',
    initialState: {
        value: 0,
        companyimage:user?.companyimage,
        companyname:user?.companyname,
        typeofcompany:user?.typeofcompany,
        Gstnumber:user?.Gstnumber,
        Cinnumber:user?.Cinnumber,
        pannumber:user?.pannumber,
        companyaddress:user?.companyaddress
    },
    reducers: {
        increase: state => {
            state.value += 1
        },
        decrease: state => {
            state.value -= 1
        },
        update: state => {
            state.companyimage = JSON.parse(localStorage.getItem('user')).companyimage;
            state.companyname = JSON.parse(localStorage.getItem('user')).companyname;
            state.typeofcompany = JSON.parse(localStorage.getItem('user')).typeofcompany;
            state.Gstnumber = JSON.parse(localStorage.getItem('user')).Gstnumber;
            state.Cinnumber = JSON.parse(localStorage.getItem('user')).Cinnumber;
            state.pannumber = JSON.parse(localStorage.getItem('user')).pannumber;
            state.companyaddress = JSON.parse(localStorage.getItem('user')).companyaddress;
        }
    }
})
// case under reducers becomes an action 
export const { increase, decrease, update } = companySlice.actions
export default companySlice.reducer 